import { createSelector } from 'reselect';
import { withLockerCounts, withAvailableCount } from '@/helpers/locations';

export const companyLocationsSelector = createSelector(
  (state) => state.locations,
  (state) => state.user?.laundry_company_id,
  (locations, laundry_company_id) => (
    _.orderBy(_.filter(locations, {laundry_company_id}), 'name')
  )
);

export const companyLocationsWithCountsSelector = createSelector(
  companyLocationsSelector,
  (state) => state.loads,
  (locations, loads) => (
    _.map(locations, withLockerCounts(loads))
  )
);

export const availableLocationsForSelectedCompany = createSelector(
  (state) => _.find(state.companies, {uuid: state.user?.selected_company_id}),
  (state) => state.locations,
  (company, locations) => {
    if (!company?.active) return [];
    const companyLocations = _.filter(locations, {laundry_company_id: company.uuid});
    return _.map(companyLocations, withAvailableCount);
  }
);
