import { IonIcon, IonText } from '@ionic/react';
import { navigateOutline } from 'ionicons/icons';
import { approximateDistance } from '@/helpers/locations';

export default function DistanceIndicator({location, userPosition}) {
  const distance = approximateDistance(location, userPosition);
  return distance && (
    <IonText color="medium">
      <IonIcon icon={navigateOutline} style={{top: '2px', position: 'relative'}}/>
      {distance}
    </IonText>
  );
}
