import { useEffect } from 'react';
import { paystriUrl } from '@/helpers/paystri';
import { defaultMemoize } from 'reselect';

const PAYSTRI_PARAMS = {
  autofocus: true,
  cardnumbernumericonly: true,
  invalidcreditcardevent: true,
  invalidcvvevent: true,
  invalidexpiryevent: true,
  selectinputdelay: 100,
  usecvv: true,
  formatinput: true,
  useexpiry: true,
  sendcardtypingevent: true,
  // these are mutually exclusive and must be used alternately on mobile and web
  tokenizewheninactive: true,
  // onblur: true,
}

const paystriUrlSelector = defaultMemoize(
  (company) => paystriUrl(PAYSTRI_PARAMS, company)
)

const PaystriFrame = ({onMessage:sendMessage, company}) => {
  const paystriFrameSrc = paystriUrlSelector(company);
  useEffect(() => {
    const parse = (event) => {
      if (event.origin !== paystriFrameSrc.origin) return;
      try {
        sendMessage(JSON.parse(event.data));
      } catch(e) {
        console.warn(e);
      }
    }
    window.addEventListener('message', parse, false);
    return () => window.removeEventListener('message', parse);
  }, []);

  if (window.Cypress) return null;
  return <iframe key="tokenFrame" src={paystriFrameSrc} frameBorder="0" scrolling="no" style={{width: '100%', height: '200px'}}/>
}
export default PaystriFrame;
