import { IonContent, IonPage, IonImg } from '@ionic/react';
import AndroidAppBanner from '@/components/AndroidAppBanner';
import { isDarkMode } from '@/helpers/platform';
import LOGO from '@/assets/logo-lg.png';
import LOGO_DARK from '@/assets/logo-lg.dark.png';


const NarrowPage = ({children}) => {
  return (
    <IonPage>
      <AndroidAppBanner/>
      <IonContent fullscreen>
        <div className="ion-padding container-small ion-margin-bottom">
          <IonImg src={isDarkMode() ? LOGO_DARK : LOGO} alt="Laundry Lockers"/>
          {children}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NarrowPage;
