import { defineCustomElements } from '@ionic/pwa-elements/loader';

defineCustomElements(window);

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { setupIonicReact, IonSpinner } from '@ionic/react';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { Provider as CableProvider } from './cable';
import mixpanel from 'mixpanel-browser';

import initStore from './store';
import App from './App';

mixpanel.init(import.meta.env.VITE_MIXPANEL_KEY || "Disable", {ignore_dnt: true});
if (!import.meta.env.VITE_MIXPANEL_KEY) mixpanel.disable();

const rollbarConfig = {
  enabled: !!import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN,
  accessToken: import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN,
  environment: import.meta.env.VITE_ENV ?? 'production',
  version: import.meta.env.VITE_GIT_COMMIT_HASH,
  transform: function(payload) {
    const trace = payload.body.trace;
    const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.(?:pages\.dev|laundry-lockers\.com)(.*)/;
    if (trace && trace.frames) {
      for (let i = 0; i < trace.frames.length; i++) {
        const filename = trace.frames[i].filename;
        if (filename) {
          const m = filename.match(locRegex);
          trace.frames[i].filename = m[1] + '://dynamichost' + m[2];          
        }
      }
    }
  }
};

setupIonicReact();

const container = document.getElementById('root');
const root = createRoot(container);

const {store, persistor, cable} = initStore();

root.render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={ErrorPage}>
        <CableProvider value={cable}>
          <Provider store={store}>
            <PersistGate loading={<IonSpinner className="app-loading"/>} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </CableProvider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>
);

function ErrorPage() {
  return (
    <p className="ion-padding" style={{textAlign: 'center'}}>
      Something went wrong. This error has been logged for review by our dev team.
      <br/>
      <a href="/">Reload</a>
    </p>
  );
}

window.addEventListener('beforeinstallprompt', (e) => {
  window.androidInstallPrompt = e;
});
