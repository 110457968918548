import { useSelector, useDispatch } from "react-redux";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent
} from '@ionic/react';

import { getLoads } from "@/actions/loads";
import { stopPropagation } from '@/helpers/events';
import { inDollars, formatDate, titleize, presentAsLine } from '@/helpers/humanize';
import { STATES, readyForPayment, current } from '@/helpers/loads';
import useResourceLoader from '@/hooks/useResourceLoader';
import { createSelector } from "reselect";

const orderedGroupedLoadsSelector = createSelector(
  (state) => state.loads,
  (loads) => _.groupBy(_.sortBy(_.filter(loads, current), '-updated_at'), 'state')
)

export default function ConsumerLoadsPage() {
  const dispatch = useDispatch();
  const loading = useResourceLoader(getLoads());
  const loads = useSelector(orderedGroupedLoadsSelector);

  const refresh = async (event) => {
    await dispatch(getLoads());
    event.target.complete();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>My Laundry</IonTitle>
          {loading && <IonProgressBar type="indeterminate"/>}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {_.isEmpty(loads) && !loading && (
          <p className="ion-padding" style={{textAlign: 'center'}}>Nothing to see here</p>
        )}

        {STATES.map((state) => _.map(loads[state], (load) => (
          <IonItem key={load.uuid} routerLink={`/loads/${load.uuid}`} routerDirection="forward">
            <IonLabel>
              <h2>{formatDate(load.created_at)}</h2>
              <p>{load.location_name}</p>
              <p>
                {presentAsLine(
                  titleize(load.state),
                  load.weight_us_lbs ? `${_.round(load.weight_us_lbs, 2)} lbs` : "unweighed",
                  load.weight_us_lbs && (load.price_cents ? inDollars(load.price_cents) : "free"),
                )}
              </p>
              <p>Last updated {formatDate(load.updated_at)}</p>
            </IonLabel>
            {readyForPayment(load) && (
              <IonButton routerLink={`/loads/${load.uuid}/pay`} onClick={stopPropagation()} slot="end" size="default">
                Pay
              </IonButton>
            )}
          </IonItem>
        )))}
      </IonContent>
    </IonPage>
  );
}
