export default function companiesReducer(state=null, action) {
  switch (action.type) {
    case 'companies/load':
      return action.companies;
    case 'company/load':
      return _.uniqBy([action.company, ...(state || [])], 'uuid');
    case 'company/setSelectedCard':
      return _.map(state, (company) => {
        if (company.uuid !== action.companyId) return company;
        return {...company, selected_card_id: action.card.id};
      });
    default:
      return state;
  }
}
