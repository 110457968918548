import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import NotFoundPage from '@/pages/NotFoundPage';
import { getCompany } from '@/actions/companies';
import { inDollars, formatDate, titleize } from '@/helpers/humanize';
import useResourceLoader from '@/hooks/useResourceLoader';

export default function ConsumerCompanyPage() {
  const {uuid} = useParams();
  const company = useSelector((state) => _.find(state.companies, {uuid}));
  const loading = useResourceLoader(getCompany(uuid));

  if (!loading && !company) <NotFoundPage type="Company"/>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"/>
          </IonButtons>
          <IonTitle>{company?.name}</IonTitle>
          {loading && <IonProgressBar type="indeterminate"/>}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {company && (
          <IonList>
            <IonItem>
              <IonLabel>
                <h4>Phone Number</h4>
                <p><a href={`tel:${company.phone_number}`}>{company.phone_number}</a></p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};
