import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import {
  IonButtons, IonBackButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  useIonViewWillEnter, useIonViewDidLeave
} from '@ionic/react';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';

export default function ScanPage({type}) {
  const history = useHistory();
  const readyLoads = useSelector((state) => _.filter(state.loads, {state: 'ready_for_pickup'}));
  const [showCamera, setShowCamera] = useState(false);

  const scanQRCode = async () => {
    const status = await BarcodeScanner.checkPermission({force: true});
    if (!status.granted) return;

    BarcodeScanner.hideBackground();
    setShowCamera(true);

    const result = await BarcodeScanner.startScan({targetedFormats: [SupportedFormat.QR_CODE]});

    const routeTarget = type + '/';
    if (result.hasContent && _.includes(result.content, routeTarget)) {
      const [, id] = result.content.split(routeTarget);
      switch(type) {
      case 'locations':
        const readyLoadAtLocation = _.find(readyLoads, {location_id: id});
        if (readyLoadAtLocation) return history.replace(`/loads/${readyLoadAtLocation.uuid}`);
        return history.replace(`/loads/new?location_id=${id}`);
      case 'loads':
        return history.replace(`/loads/${id}`);
      default:
        console.warn(`Did not find a ${type} in url:`, result.content);
        history.push('/');
      }
    } else {
      history.length ? history.goBack() : history.push('/');
    }
  }
  const stopScan = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  };

  useIonViewWillEnter(() => {
    scanQRCode().catch((e) => {
      if (e.message !== "camera already started") {
        throw(e);
      }
    })
  })
  useIonViewDidLeave(() => {
    stopScan();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"/>
          </IonButtons>
          <IonTitle>Scan QR Code</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className={classnames({showCamera})}>
        
      </IonContent>
    </IonPage>
  );
};
