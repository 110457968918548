import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonItem,
  IonList,
  IonSpinner,
  IonButton,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonText,
  IonIcon,
  IonToast,
  IonLabel,
  IonItemDivider,
  IonItemGroup
} from '@ionic/react';
import { qrCodeOutline } from 'ionicons/icons';

import { getCompany, updateCompany } from '@/actions/companies';
import { createCard } from '@/actions/cards';

import { toDollars, toCents } from '@/helpers/humanize';
import { preventDefault } from '@/helpers/events';
import { validPhoneNumber, validCost } from '@/helpers/validators';
import useResourceLoader from '@/hooks/useResourceLoader';
import LoadingPage from '@/pages/LoadingPage';
import Page from '@/pages/Page';
import PaystriFrame from '@/components/PaystriFrame';
import ValidatedInput from '@/components/ValidatedInput';

export default function CompanyPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((store) => store.user);
  const initialLoading = useResourceLoader(getCompany(user.laundry_company_id));
  const company = useSelector((store) => _.find(store.companies, {uuid: user.laundry_company_id}));
  const cards = company?.cards;

  const [phoneNumber, setPhoneNumber] = useState(company.phone_number);
  const [cost, setCost] = useState(toDollars(company.cost_cents));
  const [applyToAllLocations, setApplyToAllLocations] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notice, setNotice] = useState(null);

  const [selectedCard, setSelectedCard] = useState(company.selected_card_id);
  const [postal, setPostal] = useState("");
  const [token, setToken] = useState();
  const [creditCardError, setCreditCardError] = useState();

  const costValid = validCost(cost);
  const phoneValid = validPhoneNumber(phoneNumber);

  const costChanged = company.cost_cents != toCents(cost);
  const phoneNumberChanged = company.phone_number != phoneNumber;
  const selectedCardChanged = company.selected_card_id != selectedCard || token;

  const formChanged = _.some([phoneNumberChanged, costChanged, applyToAllLocations, selectedCardChanged]);
  const valid = costValid && phoneValid && formChanged;

  const handleCostChanged = (value) => {
    setCost(value);
    setApplyToAllLocations(true);
  };

  const submitCompanyDetails = async () => {
    setNotice(null);
    setErrorMessage(null);
    setCreditCardError(null);
    if (!valid) return;

    setLoading(true);
    if (token) { // token is true if Paystri cc details succeeded on new card
      if (!selectedCard && !postal) {
        setCreditCardError("Billing ZIP Code is required");
        setLoading(false);
        return;
      } else {
        try {
          const card = await dispatch(createCard(token, postal, company.uuid));
          setSelectedCard(card.id);
        } catch (e) {
          setCreditCardError(_.get(e, 'response.data.error', e.message));
        }
        setLoading(false);
      }
    }

    try {
      const companyParams = {
        uuid: company.uuid,
        phoneNumber,
        applyPriceChangeToAllLocations: applyToAllLocations,
        costCents: toCents(cost)
      };

      if (!token && selectedCardChanged) companyParams.selectedCardId = selectedCard;

      await dispatch(updateCompany(companyParams));
      setApplyToAllLocations(false);
      setNotice('Your company has been updated.');
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
    setLoading(false);
  };

  const setTokenFromMessageEvent = (response) => {
    if (response.validationError) {
      setCreditCardError(response.validationError);
    } else if (response.message) {
      setCreditCardError("");
      setToken(response.message);
    }
  };

  if (initialLoading) return <LoadingPage/>;
  if (user.role != 'owner') return history.push('/');

  const resourcesButton = company?.grey_label && (
    <IonButton routerLink="/company/resources">
      <IonIcon icon={qrCodeOutline}/>
    </IonButton>
  );

  return (
    <Page title={company.name} primaryButton={resourcesButton}>
      {errorMessage && <IonItem lines="none" color="danger">{errorMessage}</IonItem>}
      <form onSubmit={preventDefault(submitCompanyDetails)}>
        <IonList>
          <IonItem lines="none">
            <ValidatedInput
              label="Company Phone"
              name="phone"
              value={phoneNumber}
              setValue={setPhoneNumber}
              valid={phoneValid}/>
          </IonItem>
          <IonItem lines="none">
            <ValidatedInput
              label="Cost ($/lbs)"
              type="number"
              name="cost"
              value={cost}
              setValue={handleCostChanged}
              valid={costValid}
              errorText="Enter the cost in dollars per US pound"
              step="0.01"/>
            <IonCheckbox
              justify="start"
              labelPlacement="end"
              required
              checked={applyToAllLocations}
              onIonChange={(e) => setApplyToAllLocations(e.target.checked)}
              slot="end"
            >Apply cost to all locations
            </IonCheckbox>
          </IonItem>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Subscription</IonLabel>
            </IonItemDivider>
            <IonItem lines="none">
              <IonSelect label="Payment Method"
                value={selectedCard}
                onIonChange={(e) => {setSelectedCard(e.target.value); setCreditCardError('')}}
              >
                <IonSelectOption value={null}>New Card</IonSelectOption>
                {_.map(cards, (card) => (
                  <IonSelectOption key={card.id} value={card.id}>
                    {card.processor} *{card.last_four}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
              {selectedCard === null && <>
                <IonItem lines="none">
                  <PaystriFrame onMessage={setTokenFromMessageEvent}/>
                </IonItem>
                <IonItem lines="none">
                  <ValidatedInput label="Billing ZIP Code"
                    value={postal}
                    setValue={setPostal}
                    valid={postal.length === 5 || postal.length === 10}
                    errorText="Enter a valid 5 digit or 9 digit postal code"
                    required/>
                </IonItem>
              </>}
          </IonItemGroup>
            {creditCardError && (
              <IonItem lines="none"><IonText color="danger">{creditCardError}</IonText></IonItem>
            )}
        </IonList>
        <IonButton type="submit" expand="block" className="ion-padding" disabled={!valid || loading}>
          {loading ? <IonSpinner name="dots"/> : "Save"}
        </IonButton>
      </form>
      <IonToast
        color="success"
        isOpen={!!notice}
        message={notice}
        onDidDismiss={() => setNotice(null)}
        duration={5000}/>
    </Page>
  );
}
