import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function useResourceLoader(...loaders) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const filteredLoaders = _.filter(_.flatten(loaders), (loader) => {
      return _.isFunction(loader) || _.isString(_.get(loader, 'type'));
    });

    Promise.all(
      filteredLoaders.map((loader) => dispatch(loader))
    ).catch(console.warn).finally(() => setLoading(false));
  }, []);

  return loading;
}
