import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonModal,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent
} from '@ionic/react';

import { getLoads } from '@/actions/loads';
import { getCompanyLocations } from '@/actions/locations';
import { titleize } from '@/helpers/humanize';
import { STATES, STATE_CATEGORIES, DIRTY } from '@/helpers/loads';
import useResourceLoader from '@/hooks/useResourceLoader';
import LoadItem from '@/components/LoadItem';
import PrintLoadModal from '@/components/PrintLoadModal';

const CompanyLoadsPage = () => {
  const {category} = useParams();
  const dispatch = useDispatch();
  const loading = useResourceLoader(getLoads(), getCompanyLocations());
  const modal = useRef(null);

  const isCategory = _.includes(_.keys(STATE_CATEGORIES), category);
  const filterLoads = (loads) => {
    if (!isCategory) return _.filter(loads, {state: category});
    return _.filter(loads, ({state}) => _.includes(STATE_CATEGORIES[category], state));
  }
  const loads = useSelector((state) => _.groupBy(filterLoads(state.loads), 'state'));
  const locations = useSelector((state) => state.locations);

  const refresh = async (event) => {
    await dispatch(getLoads());
    event.target.complete();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton routerLink="/scan" fill="solid">
              Scan
            </IonButton>
          </IonButtons>
          <IonTitle>{titleize(category)} Loads</IonTitle>
          {loading && <IonProgressBar type="indeterminate"/>}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {_.isEmpty(loads) && !loading && (
          <p className="ion-padding" style={{textAlign: 'center'}}>Nothing to see here</p>
        )}
        {STATES.map((state) => loads[state] && (
          <IonItemGroup key={state}>
            {isCategory && (
              <IonItemDivider sticky>
                <IonLabel>{titleize(state)}</IonLabel>
                {state === 'dirty' && (
                  <IonButton size="small" fill="clear" color="secondary" style={{marginLeft: 'auto'}} id="open-print-modal">
                    Print All
                  </IonButton>
                )}
              </IonItemDivider>
            )}
            {_.map(_.sortBy(loads[state], ['-updated_at']), (load) => (
              <LoadItem key={load.uuid} load={load}/>
            ))}
          </IonItemGroup>
        ))}

        {!_.isEmpty(loads[DIRTY]) && (
          <IonModal ref={modal} trigger="open-print-modal">
            <PrintLoadModal loads={loads[DIRTY]} locations={locations} dismiss={() => modal.current?.dismiss()}/>
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CompanyLoadsPage;
