import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import {
  IonButton,
  IonCheckbox,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonToast
} from '@ionic/react';

import { preventDefault } from '@/helpers/events';
import { validName, validCost } from '@/helpers/validators';
import { toDollars, toCents, DAY_NAMES } from '@/helpers/humanize';
import { getLocation, updateLocation } from '@/actions/locations';
import useResourceLoader from '@/hooks/useResourceLoader';

import Page from '@/pages/Page';
import ValidatedInput from '@/components/ValidatedInput';
import ValidatedTextarea from '@/components/ValidatedTextarea';

const convertCost = (location, company) => ({
  ..._.pick(location, ['uuid', 'name', 'address', 'hours', 'promotion', 'pickup_days']),
  cost: toDollars(location.cost_cents),
  sameAsCompany: location.cost_cents === company?.cost_cents
});

export default function CompanyLocationEditPage() {
  const {uuid} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const company = useSelector((store) => _.find(store.companies, {uuid: user.laundry_company_id}));
  const locationCurrent = useSelector((state) => _.find(state.locations, {uuid}));
  const loading = useResourceLoader(getLocation(uuid));

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [notice, setNotice] = useState(null);
  const [location, setLocation] = useState(convertCost(locationCurrent, company));
  const set = _.curry((field, value) => setLocation((l) => ({...l, [field]: value})));
  const setSameAsCompany = (checked) => {
    set('sameAsCompany', checked);
    set('cost', checked ? null : toDollars(locationCurrent.cost_cents));
  };

  const nameValid = validName(location.name);
  const costValid = validCost(location.cost);
  const valid = _.every([nameValid, costValid]);

  useEffect(() => {
    if (locationCurrent?.uuid !== location?.uuid) setLocation(convertCost(locationCurrent));
  }, [locationCurrent]);

  if (user.role === 'attendant') {
    return <Redirect to="/"/>;
  }

  const saveLocation = async () => {
    setSaving(true);
    setErrorMessage('');
    try {
      const data = await dispatch(updateLocation({
        ...location,
        cost_cents: toCents(location.cost),
      }));
      setNotice("Location has been successfully updated.")
      _.delay(history.goBack, 1500);
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
    setSaving(false);
  };

  return (
    <Page title={`Edit ${location?.name}`} backDefault={`/locations/${uuid}`} loading={loading}>
      {!loading && (
        <form onSubmit={preventDefault(saveLocation)}>
          <IonList>
            <IonItem lines="none">
              <ValidatedInput label="Name"
                name="name"
                value={location.name}
                setValue={set('name')}
                valid={nameValid}
                errorText="Please enter a valid name"
                required
                autofocus/>
            </IonItem>
            <IonItem lines="none">
              <ValidatedTextarea label="Address"
                name="address"
                value={location.address}
                setValue={set('address')}
                valid
                errorText="Please enter a valid address"
                required/>
            </IonItem>
            <IonItem lines="none">
              <ValidatedInput
                label="Cost ($/lbs)"
                type="number"
                name="cost"
                value={location.cost ?? toDollars(company.cost_cents)}
                setValue={set('cost')}
                valid={costValid}
                errorText="Enter the cost in dollars per US pound"
                step="0.01"
                disabled={location.sameAsCompany}
                required/>
              <IonCheckbox slot="end"
                labelPlacement="end"
                justify="start"
                checked={location.sameAsCompany}
                onIonChange={(e) => setSameAsCompany(e.target.checked)}
              >
                Same as Company
              </IonCheckbox>
            </IonItem>
            <IonItem lines="none">
              <ValidatedTextarea label="Hours of Operation"
                name="hours"
                value={location.hours}
                setValue={set('hours')}
                placeholder={`ex: M-F 6am-10pm, S-S: 8am-8pm\nClosed Jul 4th, Dec 24, 25`}
                valid
                counter
                maxlength={80}
                rows={2}/>
            </IonItem>
            <IonItem lines="none">
              <ValidatedTextarea label="Special Promotions"
                name="promotion"
                value={location.promotion}
                setValue={set('promotion')}
                valid
                counter
                maxlength={80}
                rows={1}/>
            </IonItem>
            <IonItem>
              <IonSelect label="Pickup/Dropoff Days"
                labelPlacement="stacked"
                multiple
                value={location.pickup_days}
                onIonChange={(e) => set('pickup_days', e.detail.value)}
              >
                {DAY_NAMES.map((day) => (
                  <IonSelectOption key={day} value={day}>{day}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>
          <IonButton type="submit" expand="block" className="ion-padding" disabled={!valid || saving}>
            {saving ? <IonSpinner name="dots"/> : 'Save'}
          </IonButton>
        </form>
      )}
      <IonToast
        color="success"
        isOpen={!!notice}
        message={notice}
        onDidDismiss={() => setNotice(null)}
        duration={5000}/>
    </Page>
  );
}
