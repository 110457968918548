import { useState } from 'react';
import { IonItem, IonIcon } from '@ionic/react';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import ValidatedInput from './ValidatedInput';

export default function PasswordInput({...props}) {
  const [show, setShow] = useState(false);
  return (
    <IonItem lines="none">
      <ValidatedInput {...props}
        type={show ? 'input' : 'password'}
      />
      <IonIcon icon={show ? eyeOffOutline : eyeOutline} onClick={() => setShow(!show)}/>
    </IonItem>
  );
}
