import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IonList, IonItem, IonCard, IonText, IonIcon, IonLabel, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import { businessOutline, keyOutline, logOutOutline, warningOutline } from 'ionicons/icons';
import { logout } from '@/actions/user';
import Page from '@/pages/Page';

export default function AccountPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const userIsEmployee = user?.user_type === 'employee';
  const userIsUnlocked = user?.is_company_locked !== true;
  const logOut = async () => {
    await dispatch(logout());
    history.push('/');
  };

  return (
    <Page title="My Account">
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>{user.name}</IonCardTitle>
          {userIsEmployee && (
            <IonCardSubtitle>{user.role}</IonCardSubtitle>
          )}
        </IonCardHeader>
        <IonCardContent>
          <p>{user.email}</p>
          <p>{user.phone_number}</p>
        </IonCardContent>
      </IonCard>
      <IonList>
        {!userIsEmployee && userIsUnlocked && (
          <IonItem routerLink="/companies">
            <IonIcon aria-hidden="true" icon={businessOutline} slot="start"/>
            <IonLabel>{user.selected_company_id ? 'Change' : 'Select'} Laundry Company</IonLabel>
          </IonItem>
        )}
        <IonItem routerLink="/reset_password">
          <IonIcon aria-hidden="true" icon={keyOutline} slot="start"/>
          <IonLabel>Reset Password</IonLabel>
        </IonItem>
        <IonItem detail={false} button onClick={logOut}>
          <IonIcon aria-hidden="true" slot="start" icon={logOutOutline} />
          <IonLabel>Log Out</IonLabel>
        </IonItem>
        <IonItem href="https://laundry-lockers.com/account-deletion/">
          <IonIcon aria-hidden="true" icon={warningOutline} slot="start" color="danger"/>
          <IonLabel><IonText color="danger">Delete Account</IonText></IonLabel>
        </IonItem>
      </IonList>
    </Page>
  );
}
