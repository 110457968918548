import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonBackButton,
  IonProgressBar,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonTextarea,
  IonLabel,
  IonSpinner
} from '@ionic/react';

import { getLoad, weighLoad } from '@/actions/loads';
import { inDollars, toDollars, formatDate, titleize, loadNumber, toCents } from '@/helpers/humanize';
import { initCamera } from '@/helpers/qr_code';
import { preventDefault } from '@/helpers/events';
import useResourceLoader from '@/hooks/useResourceLoader';

import NotFoundPage from '@/pages/NotFoundPage';
import ValidatedInput from '@/components/ValidatedInput';
import ValidatedTextarea from '@/components/ValidatedTextarea';

export default function WeighPage() {
  const {uuid} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useResourceLoader(getLoad(uuid));
  const load = useSelector((state) => _.find(state.loads, {uuid}));

  const [saving, setSaving] = useState(false);
  const [weight, setWeight] = useState(load.weight_us_lbs);
  const weightValid = weight >= 0;
  const [cost, setCost] = useState(toDollars(load.cost_cents));
  const costValid = cost >= 0;
  const [additional_charge, setAdditionalCharge] = useState(toDollars(_.first(load.line_items)?.amount_cents));
  const additionalChargeValid = additional_charge === '' || !_.isNaN(parseFloat(additional_charge, 10));
  const [additional_charge_description, setAdditionalChargeDescription] = useState(_.first(load.line_items)?.description || "");
  const additionalChargeDescriptionValid = parseFloat(additional_charge || '0', 10) === 0 || additional_charge_description.length > 0;
  const valid = _.every([weightValid, costValid, additionalChargeValid, additionalChargeDescriptionValid]);

  const saveWeight = async () => {
    try {
      setSaving(true);
      const data = await dispatch(weighLoad({
        uuid,
        weight_us_lbs: weight,
        cost_cents: toCents(cost),
        line_items: additional_charge_description ? [{
          amount_cents: toCents(additional_charge),
          description: additional_charge_description,
          quantity: 1
        }] : []
      }));
      history.goBack();
    } catch (e) {
      setSaving(false);
      alert(_.get(e, 'response.data.error', e.message));
    }
  }

  if (!loading && !load) return <NotFoundPage type="Load"/>;

  return load && (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/loads/${uuid}`}/>
          </IonButtons>
          <IonTitle>Enter Weight</IonTitle>
          {(loading || saving) && <IonProgressBar type="indeterminate"/>}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <form onSubmit={preventDefault(saveWeight)}>
          <IonList key={uuid}>
            <IonItem lines="none">
              <h4>Load {loadNumber(load.number)}</h4>
            </IonItem>
            <IonItem lines="none">
              <ValidatedInput
                label="Weight (lbs)"
                type="number"
                name="weight"
                value={weight}
                setValue={setWeight}
                valid={weightValid}
                errorText="Enter a positive weight in US pounds"
                step="any"
                required
                autofocus/>
            </IonItem>
            <IonItem lines="none">
              <ValidatedInput
                label="Cost ($/lbs)"
                type="number"
                name="cost"
                value={cost}
                setValue={setCost}
                valid={costValid}
                errorText="Enter the cost in dollars per US pound"
                step="0.01"
                required/>
            </IonItem>

            <IonItem lines="none">
              <ValidatedInput
                label="Additional Charge ($)"
                type="number"
                name="charge"
                value={additional_charge}
                setValue={setAdditionalCharge}
                valid={additionalChargeValid}
                step="0.01"/>
            </IonItem>
            <IonItem lines="none">
              <ValidatedTextarea
                label="Additional Charge Description"
                name="additional_charge_description"
                value={additional_charge_description}
                setValue={setAdditionalChargeDescription}
                valid={additionalChargeDescriptionValid}
                errorText="Enter a description for the extra charge"/>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                <h4>Total Price</h4>
                {inDollars((weight * cost + parseFloat(additional_charge || '0', 10)) * 100)}
              </IonLabel>
            </IonItem>
          </IonList>
          <IonButton type="submit" expand="block" className="ion-padding" disabled={!valid || saving}>
            {saving ? <IonSpinner name="dots"/> : `${load.weight_us_lbs ? 'Update' : 'Save'} Weight and Price`}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};
