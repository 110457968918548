import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IonItem, IonLabel, IonItemDivider, IonToggle, IonSelect, IonSelectOption } from '@ionic/react';

import { titleize, formatDate } from '@/helpers/humanize';
import { companyLocationsWithCountsSelector } from '@/selectors/locations';
import { getLocation, patchLocker } from '@/actions/locations';
import useResourceLoader from '@/hooks/useResourceLoader';
import Page from '@/pages/Page';
import LoadItem from '@/components/LoadItem';

const LOCKER_STATES = ['locked', 'unlocked', 'disabled', 'malfunction'];

export default function CompanyLockerPage() {
  const {location_uuid, uuid} = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const locations = useSelector(companyLocationsWithCountsSelector);
  const location = _.find(locations, {uuid: location_uuid});
  const locker = _.find(location?.lockers, {uuid});
  const loading = useResourceLoader(!location && getLocation(location_uuid));

  const setState = ({detail: {value}}) => dispatch(patchLocker(uuid, {state: value}));
  const setOccupied = ({detail: {checked}}) => dispatch(patchLocker(uuid, {occupied: checked}));

  if (user.role === 'attendant') {
    return <Redirect to="/"/>;
  }

  return (
    <Page title={`Locker ${locker.number} at ${location.name}`} backDefault={`/locations/${location_uuid}`} loading={loading}>
      {locker && <>
        <IonItem color="warning" lines="none">
          Lockers cannot be closed remotely. Use caution when opening lockers.
        </IonItem>

        <IonItem>
          <IonLabel>
            <h4>Last Unlocked At</h4>
            {formatDate(locker.last_unlocked_at)}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonSelect label="Current State" value={locker.state} onIonChange={setState}>
            {LOCKER_STATES.map((state) => (
              <IonSelectOption key={state} value={state}>{titleize(state)}</IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonToggle checked={locker.occupied} onIonChange={setOccupied}>
            Occupied
          </IonToggle>
        </IonItem>

        {!_.isEmpty(locker.loads) && (
          <IonItemDivider sticky>
            <IonLabel>Current Load</IonLabel>
          </IonItemDivider>
        )}
        {_.map(locker.loads, (load) => (
          <LoadItem key={load.uuid} load={load}/>
        ))}
      </>}
    </Page>
  );
}
