import { IonRouterLink } from '@ionic/react';
import SignUpForm from '@/components/SignUpForm';
import NarrowPage from '@/components/NarrowPage';

const SignUpPage = () => {
  return (
    <NarrowPage>
      <SignUpForm/>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <IonRouterLink routerLink="/" routerDirection="back">Log In</IonRouterLink>
      </div>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <a href="http://laundry-lockers.com/privacy-policy/" target="_blank">Privacy Policy</a>
      </div>
    </NarrowPage>
  );
};

export default SignUpPage;
