import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { IonItem, IonLabel, IonButton, IonText, IonChip, IonBadge } from '@ionic/react';

import { stopEverything } from '@/helpers/events';
import { availabilityStatusClass } from '@/helpers/locations';
import { timeAgo, titleize, presentAsLine, loadNumber } from '@/helpers/humanize';
import { companyLocationsWithCountsSelector } from '@/selectors/locations';
import { getLocation, patchLocker } from '@/actions/locations';
import useResourceLoader from '@/hooks/useResourceLoader';
import Page from '@/pages/Page';
import LocationCard from '@/components/LocationCard';

const numericOrder = (lockers) => _.sortBy(lockers, [
  ({number}) => !_.isFinite(_.toNumber(number)),
  ({number}) => _.toNumber(number) || number
]);

const OccupiedIndicator = ({occupied}) => (
  <IonBadge color={occupied ? 'medium' : "tertiary"}>
    {occupied ? 'occupied' : 'empty'}
  </IonBadge>
);
const StatusBadge = ({state, occupied, currentLoad}) => {
  if (state === 'disabled' || state === 'malfunction') {
    return <IonBadge color="danger">{state}</IonBadge>;
  } else if (state === 'unlocked') {
    return <IonBadge color="warning">{state}</IonBadge>;
  }
}

export default function CompanyLocationPage() {
  const {uuid} = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const locations = useSelector(companyLocationsWithCountsSelector);
  const location = _.find(locations, {uuid});
  const loading = useResourceLoader(getLocation(uuid));

  const open = (lockerId) => stopEverything(() => dispatch(patchLocker(lockerId, {state: 'unlocked'})));

  if (user.role === 'attendant') {
    return <Redirect to="/"/>;
  }

  const editButton = <IonButton routerLink={`/locations/${uuid}/edit`} fill="solid">Edit</IonButton>;
  return (
    <Page title={location?.name} backDefault="/locations" loading={loading} primaryButton={editButton}>
      <IonItem color="warning" lines="none">
        Lockers cannot be closed remotely. Use caution when opening lockers.
      </IonItem>

      <LocationCard location={location}/>

      <IonItem lines="none">
        <IonChip color={availabilityStatusClass(location)}>
          {location?.availableLockers}/{location?.totalLockers} lockers available.
        </IonChip>
      </IonItem>
      {_.map(numericOrder(location?.lockers), (locker) => (
        <IonItem key={locker.uuid} routerLink={`/locations/${uuid}/lockers/${locker.uuid}`}>
          <IonLabel>
            <h2>{locker.number} <OccupiedIndicator occupied={locker.occupied}/></h2>
            <p>
              {locker.currentLoad && <>
                <IonText color={locker.currentLoad.state === "dirty" ? "success" : "medium"}>
                  <b>{titleize(locker.currentLoad.state)}</b>
                </IonText>
                {" • "}
                {presentAsLine(
                  loadNumber(locker.currentLoad.number),
                  timeAgo(locker.currentLoad.updated_at)
                )}
              </>}
            </p>
          </IonLabel>
          {locker.state === 'locked' ? (
            <IonButton onClick={open(locker.uuid)} color="secondary">Open</IonButton>
          ) : (
            <StatusBadge state={locker.state}/>
          )}
        </IonItem>
      ))}
    </Page>
  );
}
