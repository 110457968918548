import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Capacitor } from '@capacitor/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonBackButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonProgressBar,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonText
} from '@ionic/react';
import { scanOutline } from 'ionicons/icons';

import { getLoad } from '@/actions/loads';

import NotFoundPage from '@/pages/NotFoundPage';
import { inDollars, formatDate, titleize, loadNumber } from '@/helpers/humanize';
import useResourceLoader from '@/hooks/useResourceLoader';
import Page from '@/pages/Page';
import NextStateButton from '@/components/NextStateButton';
import PrintLoadModal from '@/components/PrintLoadModal';
import LockerOpenModal from '@/components/LockerOpenModal';
import PriceDescription from '@/components/loads/PriceDescription';

const CompanyLoadPage = () => {
  const {uuid} = useParams();
  const loading = useResourceLoader(getLoad(uuid));
  const user = useSelector((state) => state.user);
  const load = useSelector((state) => _.find(state.loads, {uuid}));
  const location = useSelector((state) => load ? _.find(state.locations, {uuid: load.location_id}) : undefined);
  const modal = useRef(null);

  if (!loading && !load) return <NotFoundPage type="Load"/>;

  const showCancel = ["manager", "owner"].includes(user.role) && load?.state != "canceled";
  const showReweigh = load?.payment_status !== 'paid' && _.includes(['weighed', 'accepted', 'rejected'], load?.state);

  return load && <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/loads/${load.state}`}/>
          </IonButtons>
          <IonButtons slot="primary">
            {!Capacitor.isNativePlatform() && (
              <IonButton fill="solid" id="open-modal">
                Print
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>Load {loadNumber(load.number)} for {load.consumer_name || load.consumer_email}</IonTitle>
          {loading && <IonProgressBar type="indeterminate"/>}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/scan">
            <IonIcon icon={scanOutline}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonList>
          <IonItem>
            <IonLabel>
              <h4>Pickup/Dropoff Location</h4>
              {load.location_name}
              <p>{location?.address}</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h4>Customer Dropped This Off At</h4>
              {formatDate(load.created_at)}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h4>Current State</h4>
              {titleize(load.state)}{load.status_note ? ` (${load.status_note})` : ''}
              <p>Last updated {formatDate(load.updated_at)}</p>
            </IonLabel>
            <NextStateButton load={load} user={user} location={location}/>
          </IonItem>
          {load.washing_instructions && (
            <IonItem>
              <IonLabel>
                <h4>Washing Instructions</h4>
                {load.washing_instructions}
              </IonLabel>
            </IonItem>
          )}
          <IonItem>
            <IonLabel>
              <h4>Weight</h4>
              {_.isNil(load.weight_us_lbs) ? "unweighed" : `${_.round(load.weight_us_lbs, 2)} lbs`}
            </IonLabel>
            {showReweigh && (
              <IonButton routerLink={`/loads/${load.uuid}/weigh`} color="secondary">
                Change Weight & Price
              </IonButton>
            )}
          </IonItem>
          <IonItem>
            <IonLabel>
              <h4>Price</h4>
              {!_.isNil(load.weight_us_lbs) ? <>
                {load.price_cents ? inDollars(load.price_cents) : "free"}
                <small>{load.cost_cents ? ` (${inDollars(load.cost_cents)}/lbs)` : ""}</small>
                <PriceDescription load={load}/>
              </> : "N/A"}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h4>Payment</h4>
              {titleize(load.payment_status)}
              {load.payment_message ? ` (${load.payment_message})` : ""}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h4>Customer</h4>
              {load.consumer_name}
              <p><a href={`tel:${load.consumer_phone_number}`}>{load.consumer_phone_number}</a></p>
            </IonLabel>
          </IonItem>
          {showCancel && <IonItem routerLink={`/loads/${load.uuid}/cancel`}>
            <IonText color="warning">Cancel Load</IonText>
          </IonItem>}
        </IonList>
        <IonModal ref={modal} trigger="open-modal">
          <PrintLoadModal load={load} location={location} dismiss={() => modal.current?.dismiss()}/>
        </IonModal>
      </IonContent>
    </IonPage>
    <LockerOpenModal user={user} load={load} location={location}/>
  </>;
};

export default CompanyLoadPage;
