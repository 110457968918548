import Point from '@/helpers/point';

export const UNAVAILABLE_CLASS = 'danger';
export const RUNNING_LOW_CLASS = 'warning';
export const AVALABLE_CLASS = 'tertiary';

export const userInRange = (location, userPosition) => import.meta.env.VITE_DEV_IGNORE_GEOFENCE || (
  userPosition && location && new Point(userPosition).distanceToFeet(new Point(location)) < location.proximity
);

export const approximateDistance = (location, userPosition) => {
  if (userPosition && location) {
    const distance = userPosition.distanceToFeet(new Point(location));
    if (distance < 1000) return `${_.round(distance, distance < 250 ? 0 : 1)} ft`;
    else return `${_.round(distance/5280, 1)} mi`;
  }
};

export const withAvailableCount = (location) => location && ({
  ...location,
  totalLockers: location.lockers?.length,
  availableLockers: _.filter(location.lockers, {occupied: false, state: 'locked'}).length
});

export const withDirtyCount = (loads) => (location) => location && ({
  ...location,
  dirtyLockers: _.filter(loads, {state: 'dirty', location_id: location.uuid}).length
});

export const withCurrentLoad = (loads) => (location) => location && ({
  ...location,
  lockers: _.map(location.lockers, (locker) => {
    const dirtyLoads = _.filter(loads, {state: 'dirty', pickup_locker_id: locker.uuid});
    const readyLoads = _.filter(loads, {state: 'ready_for_pickup', delivery_locker_id: locker.uuid});
    const relatedLoads = [...dirtyLoads, ...readyLoads];
    return {
      ...locker,
      loads: relatedLoads,
      currentLoad: _.first(relatedLoads)
    };
  })
});

export const withLockerCounts = (loads) => _.flow(withAvailableCount, withDirtyCount(loads), withCurrentLoad(loads));

export const availabilityStatusClass = ({availableLockers, totalLockers}) => {
  if (availableLockers === 0) return UNAVAILABLE_CLASS;
  if (availableLockers <=2 || availableLockers / totalLockers < 0.25) return RUNNING_LOW_CLASS;
  return AVALABLE_CLASS;
};
