import { MarkerF } from '@react-google-maps/api';
import Point from '@/helpers/point';

const CIRCLE_PATH = "M -1, 0 a 1,1 0 1,1 2,0 a 1,1 0 1,1 -2,0"

export default function UserMarker({location}) {
  return (
    <MarkerF
      title="Current Location"
      icon={{
        path: CIRCLE_PATH,
        fillColor: "#3880ff",
        fillOpacity: 0.8,
        scale: 8,
        strokeWeight: 3,
        strokeOpacity: 0.7,
        strokeColor: "#fff",
      }}
      position={new Point(location)}
      zIndex={0}
    />
  );
}
