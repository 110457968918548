import { useSelector, useDispatch } from 'react-redux';
import { IonItem, IonLabel, IonBadge } from '@ionic/react';
import pluralize from 'pluralize';

import { getCompanyLocations } from '@/actions/locations';
import { getLoads } from "@/actions/loads";
import { availabilityStatusClass } from '@/helpers/locations';
import { companyLocationsWithCountsSelector } from '@/selectors/locations';
import useResourceLoader from '@/hooks/useResourceLoader';
import Page from '@/pages/Page';

export default function CompanyLocationsPage() {
  const {laundry_company_id} = useSelector((state) => state.user);
  const locations = useSelector(companyLocationsWithCountsSelector);
  const loading = useResourceLoader(getCompanyLocations(laundry_company_id), getLoads());

  return (
    <Page title="Locations" loading={loading}>
      {_.map(locations, (location) => (
        <IonItem key={location.uuid} routerLink={`/locations/${location.uuid}`} routerDirection="forward">
          <IonLabel>
            <h2>{location.name}</h2>
            <p>{pluralize('lockers', location.dirtyLockers, true)} with dirty loads</p>
          </IonLabel>
          <IonBadge color={availabilityStatusClass(location)}>
            {location.availableLockers}/{location.totalLockers} lockers available
          </IonBadge>
        </IonItem>
      ))}
    </Page>
  );
}
