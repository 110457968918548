import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IonBackButton, IonButton, IonCheckbox, IonIcon, IonItem, IonSpinner, IonToast } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

import { preventDefault } from '@/helpers/events';
import { validPassword, validPasswordConfirmation } from '@/helpers/validators';
import { resetPassword } from '@/actions/user';

import ValidatedInput from '@/components/ValidatedInput';

const useToggle = (defaultVal) => {
  const [val, setVal] = useState(defaultVal);
  const toggle = () => setVal(!val);
  return [val, toggle];
}

const ResetPasswordForm = ({initial}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, toggleShowPassword] = useToggle(false);
  const [agree, setAgree] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [notice, setNotice] = useState();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const passwordValid = validPassword(password);
  const passwordConfirmationValid = validPasswordConfirmation(password, passwordConfirmation);
  const agreeValid = !initial || agree;
  const valid = _.every([passwordValid, passwordConfirmationValid, agreeValid]);

  const submitResetPassword = async () => {
    setNotice(null);
    setErrorMessage(null);
    if (!valid) return;

    setLoading(true);
    try {
      await dispatch(resetPassword(password, agree));
      setNotice('Your password has been updated');
      if (initial) history.replace('/');
    } catch(e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
    setLoading(false);
  }

  return <>
    <form style={{textAlign: 'left'}} onSubmit={preventDefault(submitResetPassword)}>
      {errorMessage && <IonItem color="danger">{errorMessage}</IonItem>}
      <IonItem lines="none">
        <ValidatedInput label="Password"
          type={showPassword ? 'input' : 'password'}
          name="password"
          value={password}
          setValue={setPassword}
          valid={passwordValid}
          errorText="Passwords must be at least 8 characters long"
          required/>
          <IonIcon slot="end" icon={showPassword ? eyeOffOutline : eyeOutline} onClick={toggleShowPassword}/>
      </IonItem>
      <IonItem lines="none">
        <ValidatedInput label="Password confirmation"
          type={showPassword ? 'input' : 'password'}
          name="password_confirmation"
          value={passwordConfirmation}
          setValue={setPasswordConfirmation}
          valid={passwordConfirmationValid}
          errorText="Passwords must match"
          required/>
          <IonIcon slot="end" icon={showPassword ? eyeOffOutline : eyeOutline} onClick={toggleShowPassword}/>
      </IonItem>
      {initial && (
        <IonCheckbox
          labelPlacement="end"
          required
          className="ion-padding-vertical"
          checked={agree}
          onIonChange={(e) => setAgree(e.target.checked)}
        >
          I agree to the <a href="http://laundry-lockers.com/terms-conditions/" target="_blank" style={{pointerEvents: 'all'}}>
            terms and conditions
          </a>
        </IonCheckbox>
      )}
      <IonButton type="submit" expand="block" disabled={!valid || loading}>
        {loading ? <IonSpinner name="dots"/> : `${initial ? 'Set' : 'Update'} Password`}
      </IonButton>
      {!initial && <IonBackButton type="button" fill="solid" expand="block" defaultHref="/" icon="" text="Cancel"/>}
    </form>
    <IonToast
      color="success"
      isOpen={!!notice}
      message={notice}
      onDidDismiss={() => setNotice(null)}
      duration={5000}/>
  </>
}

export default ResetPasswordForm;
