import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonProgressBar,
  useIonRouter
} from '@ionic/react';
import { Geolocation } from '@capacitor/geolocation';

import { getCompanies } from '@/actions/companies';
import { setSelectedCompany, setUserLocation } from '@/actions/user';
import Point from '@/helpers/point';
import { activeCompaniesSelector, activeCompanySelectedSelector } from '@/selectors/companies';
import Page from '@/pages/Page';

const CompanySelectionPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const activeCompanySelected = useSelector(activeCompanySelectedSelector);
  const isLocked = useSelector((store) => store.user?.is_company_locked);
  const companies = useSelector(activeCompaniesSelector);

  useEffect(() => {
    Geolocation.getCurrentPosition().then(({coords}) => {
      const point = new Point(coords);
      dispatch(getCompanies({location: point.toString()})).then(() => setLoading(false));
      dispatch(setUserLocation(point));
    }).catch((e) => {
      console.warn('did not find location', e)
      dispatch(getCompanies()).then(() => setLoading(false));;
    })
  }, []);

  const selectCompany = (uuid) => () => dispatch(setSelectedCompany(uuid));

  if (isLocked && activeCompanySelected) return <Redirect to="/"/>;

  return (
    <Page title="Select Company"
      backDefault="/account"
      primaryButton={
        <IonButton routerLink="/scan" fill="solid">
          Scan
        </IonButton>
      }
      loading={loading}
    >
      <IonList>
        {_.map(companies, (company) => (
          <IonItem key={company.uuid}
            onClick={selectCompany(company.uuid)}
            routerLink="/map"
            routerDirection="forward"
          >
            <IonLabel>{company.name}</IonLabel>
          </IonItem>
        ))}
      </IonList>
    </Page>
  );
};

export default CompanySelectionPage;
