import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { getCompany } from '@/actions/companies';
import { getLoad } from '@/actions/loads';
import { readyForPayment } from '@/helpers/loads'
import { inDollars, formatDate, titleize, presentAsLine, loadNumber } from '@/helpers/humanize';
import useResourceLoader from '@/hooks/useResourceLoader';
import NotFoundPage from '@/pages/NotFoundPage';
import NextStateButton from '@/components/NextStateButton';
import LockerOpenModal from '@/components/LockerOpenModal';
import PriceDescription from '@/components/loads/PriceDescription';

const CompanyInfo = ({id:uuid}) => {
  const company = useSelector((state) => _.find(state.companies, {uuid}));
  const loading = useResourceLoader(!company && getCompany(uuid));

  return !loading && company && (
    <IonItem>
      <IonLabel>
        <h4>Laundry Company</h4>
        {company.name}
        {company.phone_number && (
          <p><a href={`tel:${company.phone_number}`}>{company.phone_number}</a></p>
        )}
      </IonLabel>
    </IonItem>
  );
};

const ConsumerLoadPage = () => {
  const {uuid} = useParams();
  const user = useSelector((state) => state.user);
  const load = useSelector((state) => _.find(state.loads, {uuid}));
  const location = useSelector((state) => _.find(state.locations, {uuid: load?.location_id}));
  const loading = useResourceLoader(getLoad(uuid));

  if (!loading && !load) {
    return <NotFoundPage type="Load"/>;
  }

  return !loading && <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/loads"/>
          </IonButtons>
          <IonTitle>{formatDate(load.created_at)}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel>
              <h4>Pickup/Dropoff Location</h4>
              {load.location_name}
              <p>{location?.address}</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h4>You dropped this off at</h4>
              {formatDate(load.created_at)}
              <p>Load {loadNumber(load.number)}</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h4>Current State</h4>
              {titleize(load.state)}{load.status_note ? ` (${load.status_note})` : ''}
              <p>Last updated {formatDate(load.updated_at)}</p>
            </IonLabel>
            <NextStateButton load={load} location={location} user={user}/>
          </IonItem>
          {load.washing_instructions && (
            <IonItem>
              <IonLabel>
                <h4>Washing Instructions</h4>
                {load.washing_instructions}
              </IonLabel>
            </IonItem>
          )}
          <IonItem>
            <IonLabel>
              <h4>Weight</h4>
              {_.isNil(load.weight_us_lbs) ? "Unweighed" : `${_.round(load.weight_us_lbs, 2)} lbs`}
            </IonLabel>
          </IonItem>
          {!_.isNil(load.weight_us_lbs) && (
            <IonItem>
              <IonLabel>
                <h4>Price</h4>
                {load.price_cents ? inDollars(load.price_cents) : "Free"}
                <small>{load.cost_cents ? ` (${inDollars(load.cost_cents)} / lbs)` : ""}</small>
                <PriceDescription load={load}/>
              </IonLabel>
            </IonItem>
          )}
          {load.price_cents > 0 && (
            <IonItem>
              <IonLabel>
                <h4>Payment</h4>
                {titleize(load.payment_status)}
                {load.payment_message ? ` (${load.payment_message})` : ""}
              </IonLabel>
              {readyForPayment(load) && (
                <IonButton routerLink={`/loads/${uuid}/pay`} slot="end" size="default">
                  Pay
                </IonButton>
              )}
            </IonItem>
          )}
          <CompanyInfo id={load.laundry_company_id}/>
        </IonList>
      </IonContent>
    </IonPage>
    <LockerOpenModal user={user} load={load} location={location}/>
  </>;
};

export default ConsumerLoadPage;
