import axios from 'axios';

const METHODS = ['get', 'post', 'patch', 'delete'];
const snakeKeys = (obj) => _.mapKeys(obj, (v, k) => _.snakeCase(k));

const BASE_URL = getBaseUrl();

const logoutWhenUnauthorized = (dispatch) => (error) => {
  if (error.response?.status === 401) {
    dispatch({type: 'auth/logout'});
  }
  return Promise.reject(error);
}

export default function createAPI(getToken, dispatch) {
  const base = () => {
    const api = axios.create({
      baseURL: BASE_URL,
      responseType: 'json',
      headers: {
       'Accept': 'application/json',
       'Authorization': `Bearer ${getToken()}`
     }
    });
    api.logoutWhenUnauthorized = api.interceptors.response.use(_.identity, logoutWhenUnauthorized(dispatch));
    return _.pick(api, ...METHODS, 'create', 'logoutWhenUnauthorized');
  }

  const [get, post, patch, delete_] = METHODS.map((method) => (...args) => base()[method](...args));

  return {
    base,

    login: ({email, password}) => post('auth/login', {email, password}),
    signUp: (user) => post('user', {user: snakeKeys(user)}),
    forgotPassword: (email) => post('auth/magic_link', {magic_link: {email}}),
    resetPassword: (password, agree) => patch('user/password', {password, agree}),
    confirmEmail: (confirmationCode) => post('user/confirmation', {user: snakeKeys({confirmationCode})}),
    requestConfirmationEmail: () => post('user/confirmation_request'),
    getUser: () => get('user'),
    getUserWithToken: (token) => {
      const defaultAxios = base();
      const instance = defaultAxios.create();
      instance.interceptors.request.eject(defaultAxios.logoutWhenUnauthorized);
      return instance.get('user', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {with_token: ''}
      })
    },
    updateUser: (user) => patch('user', {user: snakeKeys(user)}),
    getCompanies: ({location}) => (location && _.trim(location) != ",") ? get('companies', {params: {location}}) : get('companies'),
    getCompany: (uuid) => get(`companies/${uuid}`),
    updateCompany: (company) => patch(`companies/${company.uuid}`, {company: snakeKeys(company)}),
    getCompanyLocations: (uuid) => get(`companies/${uuid}/locations`),
    getLocation: (uuid) => get(`locations/${uuid}`),
    updateLocation: (uuid, location) => patch(`locations/${uuid}`, {location: snakeKeys(location)}),
    getLoads: () => get('loads'),
    getLoad: (uuid) => get(`loads/${uuid}`),
    createLoad: (load) => post(`loads`, {load: snakeKeys(load)}),
    updateLoad: (uuid, load) => patch(`loads/${uuid}`, {load: snakeKeys(load)}),
    updateLocker: (uuid, locker) => patch(`lockers/${uuid}`, {locker: snakeKeys(locker)}),
    createCard: (card) => post('cards', {card: snakeKeys(card)}),
    searchConsumers: (consumerSearchParams) => post('consumers/search', {consumer: snakeKeys(consumerSearchParams)}),
    createConsumer: (consumer) => post('consumers', {consumer: snakeKeys(consumer)}),
  };
}

function getBaseUrl() {
  try  {
    return import.meta.env.VITE_API;
  }
  catch {
    return '/api/v1/';
  }
}
