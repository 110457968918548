export default function lockersTranslator(message, dispatch) {
  switch(message.update) {
    case "locker updated":
    case "locker unlocked":
    case "locker locked":
    case "locker occupied":
    case "locker empty":
      dispatch({
        type: "locker/update",
        locker_id: message.locker.uuid,
        location_id: message.locker.location_id,
        locker: message.locker
      });
  }
}
