import {formatDistanceToNow} from 'date-fns';

export const toDollars = (cents) => cents ? (cents/100).toFixed(2) : '';
export const toCents = (dollars) => dollars ? _.round(dollars * 100) : '';
export const inDollars = (cents) => `$${toDollars(cents)}`;
export const formatDate = (date) => new Date(date).toLocaleDateString('en-us', {hour: "numeric", minute: "numeric", month:"short", day:"numeric"});
export const timeAgo = (date) => formatDistanceToNow(new Date(date), {addSuffix: true});
export const titleize = (string) => _.kebabCase(string).split('-').map(_.capitalize).join(' ');
export const presentAsLine = (...items) => _.join(_.compact(items), ' • ');
export const loadNumber = (loadNumber) => '#' + _.padStart(loadNumber, 8, '0');
export const DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
export const daysOfTheWeek = (days) => days?.length === 7 ? "every day" : listFormatter.format(days);
