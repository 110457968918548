import { useDispatch } from 'react-redux';
import {
  IonButton,
  IonPage,
  IonHeader,
  IonModal,
  IonToolbar,
  IonTitle,
  IonContent
} from '@ionic/react';

import { PICKUP_STATES } from '@/helpers/loads';
import { getLocation } from '@/actions/locations';

const CONTENT = {
  consumer: {
    begin: {
      title: "Dropping Off Load",
      body: (locker) => `Place your laundry in locker #${locker.number} and close the door.`
    },
    end: {
      title: "Take Clean Load",
      body: (locker) => `Please remove your clean laundry from locker #${locker.number} and close the door.`
    }
  },
  employee: {
    begin: {
      title: "Collecting Dirty Load",
      body: (locker) => `Place tag on laundry and close locker #${locker.number}.`
    },
    end: {
      title: "Deliver Clean Load",
      body: (locker) => `Place clean laundry in locker #${locker.number} and close the door.`
    }
  }
};

const findLocker = (location, uuid) => _.find(location?.lockers, {uuid});
const unlockedForUser = (user, locker) => user.uuid === locker?.last_unlocked_by_id;
const modalView = (user_type, state='dirty') => {
  const phase = _.includes(PICKUP_STATES, state) ? "begin" : "end";
  return _.get(CONTENT, [user_type, phase]);
};

const LockerOpenModal = ({user, load, location}) => {
  const dispatch = useDispatch();
  const reload = () => dispatch(getLocation(location.uuid));

  const locker = findLocker(location, load?.delivery_locker_id || load?.pickup_locker_id);
  const lockerOpen = locker?.state === 'unlocked' && unlockedForUser(user, locker);
  const view = modalView(user.user_type, load?.state);

  return (
    <IonModal isOpen={lockerOpen} backdropDismiss={false}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{view.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <p>
            {locker && view.body(locker)}
          </p>
          <IonButton onClick={reload}>I closed the door</IonButton>
        </IonContent>
      </IonPage>
    </IonModal>
  );
}

export default LockerOpenModal;
