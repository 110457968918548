import { useEffect, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/react';
import { formatDate, titleize, loadNumber } from '@/helpers/humanize';
import { loadQRCode } from '@/helpers/qr_code';

const CSS_SUPPRESS_FOOTERS = `
  @media print {
    @page { margin: 0.25in auto; }
    body { position: initial !important; }
  }
`;
const CSS_ITEM_LINES = `
  .item-lines-default {
    display: block;
    padding: 0.075in 0;
    border-bottom: 1px solid rgb(200, 199, 204);
  }
`;
const CSS_PAGE_BREAKS = `
  .pick-ticket {
    page-break-after: always;
    break-after: page;
  }
`;
const CSS_OFF_SCREEN = `
  width: 1280px;
  display: block;
  position: absolute;
  left: 100vw;
  top: 0;
`;

const webPrint = (content) => {
  const iframe = document.createElement('iframe');
  iframe.style = CSS_OFF_SCREEN;
  iframe.srcdoc = `
    <html class="hydrated">
      <head>
        ${window.document.head.innerHTML}
        <style>${CSS_SUPPRESS_FOOTERS}${CSS_ITEM_LINES}${CSS_PAGE_BREAKS}</style>
      <head>
      <body>
        ${content}
        <script>window.print()</script>
      </body>
    </html>`;
  document.body.appendChild(iframe);
  setTimeout(() => iframe.remove(), 1000);
};

function LoadPickTicket({load, location}) {
  return (
    <div className="pick-ticket ion-padding">
      <IonList>
        <IonItem className="ion-text-center" lines="none">
          <IonLabel>
            <img src={loadQRCode(load)} width="180"/>
            <h1>{loadNumber(load.number)}</h1>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h4>Customer</h4>
            <strong>{load.consumer_name}</strong>
            <p>{load.consumer_phone_number}</p>
            <p>{load.consumer_email}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h4>Dropped off</h4>
            <strong>{load.location_name}</strong>
            <p>{location?.address}</p>
            {formatDate(load.created_at)}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <h4>Washing Instructions</h4>
            {load.washing_instructions || <em>None</em>}
          </IonLabel>
        </IonItem>
      </IonList>
    </div>
  );
}

export default function PrintLoadModal({load, loads=load, location, locations=location, dismiss}) {
  loads = _.flatten([loads]);
  locations = _.flatten([locations]);
  const printableElement = useRef(null);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      window.print();
    } else {
      _.defer(() => {
        webPrint(printableElement.current.innerHTML);
        dismiss();
      })
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Print {loads.length === 1 ? (`Load ${loadNumber(load.number)}` ) : "Dirty Loads"}
          </IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={dismiss}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={printableElement}>
        {loads.map((load) => (
          <LoadPickTicket key={load.uuid} load={load} location={_.find(locations, {uuid: load.location_id})}/>
        ))}
      </IonContent>
    </IonPage>
  );
}
