import { getCompany } from './companies';

export function getCompanyLocations(uuid) {
  return async (dispatch, getState, {api, cable}) => {
    if (!uuid) uuid = _.get(getState(), 'user.laundry_company_id');
    const {data:{locations}={}} = await api.getCompanyLocations(uuid);
    dispatch({type: 'companyLocations/load', locations});
    _.each(locations, (location) => cable.subscribe('LocationChannel', {id: location.uuid}, getLocation(location.uuid)));
    return locations;
  };
}

export function getLocation(uuid) {
  if (_.isEmpty(uuid)) throw new TypeError("uuid is a required parameter");

  return async (dispatch, getState, {api, cable}) => {
    await cable.subscribe('LocationChannel', {id: uuid}, getLocation(uuid));
    const {data} = await api.getLocation(uuid);
    dispatch({type: 'companyLocation/load', location: data.location});
    if (!_.find(getState().companies, {uuid: data.location.laundry_company_id})) {
      getCompany(data.location.laundry_company_id);
    }
    return data.locations;
  }
}

export function updateLocation({uuid, ...location}) {
  return async (dispatch, getState, {api}) => {
    const {data} = await api.updateLocation(uuid, location);
    dispatch({type: 'companyLocation/load', location: data.location});
    return data.location;
  };
}

export function patchLocker(id, locker) {
  return async (dispatch, getState, {api}) => {
    const {data} = await api.updateLocker(id, locker);
    dispatch({type: 'locker/update', locker_id: id, locker: data.locker});
    return data.locker;
  };
}
