import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useRollbarPerson } from '@rollbar/react';
import mixpanel from 'mixpanel-browser';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

import { loginWithToken, refreshToken, forgotPassword, logout } from '@/actions/user';
import { useIonToast } from '@ionic/react';
import jwtDecode from 'jwt-decode';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

const processTokenLogin = ({searchParams, loading, setLoading, presentTokenErrorToast, dispatch, loginWithToken, forgotPassword, history, pathname}) => {
  const token = searchParams.get('mt');
  try {
    if (!loading) {
      setLoading(true);
      const payload = jwtDecode(token);
      const expired = Date.now() >= payload.exp * 1000;
      const buttons = [{handler: () => dispatch(forgotPassword(payload.email)), text: 'Resend'}];

      if (expired) {
        presentTokenErrorToast({message: 'Link is expired', duration: 5000, buttons, color: 'warning'})
        return;
      }

      dispatch(loginWithToken(token)).then(() => {
        if (pathname) {
          if (searchParams.toString().length) {
            history.push(`${pathname}?${searchParams.toString()}`);
          } else {
            history.push(pathname);
          }
        }
      }).catch((e) => {
        console.warn(e)
        presentTokenErrorToast({message: 'Link is invalid', duration: 5000, buttons, color: 'warning'})
      }).finally(() => {
        setLoading(false);
      });
    }
  } catch(e) {
    console.warn(e);
    presentTokenErrorToast({message: 'Link is invalid', duration: 5000, buttons, color: 'warning'})
  }

  if (pathname) {
    searchParams.delete('mt')
    if (searchParams.toString().length) {
      history.push(`${pathname}?${searchParams.toString()}`);
    } else {
      history.push(pathname);
    }
  }
}

const saveLaundryCompany = ({searchParams, pathname, history}) => {
  const laundryCompanyId = searchParams.get('lc');
  localStorage.setItem('laundryCompanyId', laundryCompanyId);
  return; // gonna bail early for now... want to test if opening Android will pick up the path
  searchParams.delete('lc')

  if (searchParams.toString().length) {
    history.replace(`${pathname}?${searchParams.toString()}`);
  } else {
    history.replace(pathname);
  }
};

const updateAppBanner = (pathname, user) => {
  const searchParams = new URLSearchParams();
  if (user?.token) {
    searchParams.set('mt', user.token);
  } else if (localStorage.getItem('laundryCompanyId')) {
    searchParams.set('lc', localStorage.getItem('laundryCompanyId'));
  }
  const appBanner = document.querySelector("meta[name=apple-itunes-app]");
  appBanner.content = appBanner.content.replace(/lockers.com(.*)/, `lockers.com${pathname}?${searchParams.toString()}`);
};

const AppUrlListener = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [presentTokenErrorToast, _dismissTokenErrorToast] = useIonToast();

  const user = useSelector((store) => store.user);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    App.addListener('appUrlOpen', ({url}) => {
      const {pathname, search} = new URL(url);
      if (pathname) history.push(pathname + search);
    });
  }, []);

  useEffect(() => {
    mixpanel.identify(user?.email);
    if (user?.email) {
      mixpanel.people.set(_.pick(user, ['email', 'user_type', 'role', 'name']));
    }
  }, [user?.email]);

  useEffect(() => {
    if (!user?.token) return;

    const timeTillExpire = user.token_exp * 1000 - new Date();
    const timeSinceIssue = new Date() - user.token_iss;
    if (timeTillExpire < 1) {
      dispatch(logout());
    } else if (timeSinceIssue > ONE_DAY_MS) {
      dispatch(refreshToken());
    } else {
      const timeout = setTimeout(() => dispatch(refreshToken()), ONE_DAY_MS);
      return () => clearTimeout(timeout);
    }
  }, [user?.token]);

  useEffect(() => {
    mixpanel.track('Page visit');

    const {pathname, search} = location;

    const searchParams = new URLSearchParams(search);

    if (searchParams.has('mt')) {
      processTokenLogin({searchParams, loading, setLoading, presentTokenErrorToast, dispatch, loginWithToken, forgotPassword, history, pathname});
    }

    if (searchParams.has('lc')) {
      saveLaundryCompany({searchParams, pathname, history});
    }
    if (!Capacitor.isNativePlatform()) updateAppBanner(pathname, user);
  }, [location]);

  useEffect(() => {
    const onConfirmationPath = location.pathname === '/email_confirmation';
    const consumer = user?.user_type === 'consumer';
    if (consumer && !user.email_confirmed && !onConfirmationPath) {
      history.push('/email_confirmation');
    }
    if (consumer && user?.email_confirmed && onConfirmationPath) {
      history.replace('/');
    }
  }, [user, location]);

  useRollbarPerson(user);
};

export default AppUrlListener;
