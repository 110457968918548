import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonProgressBar
} from '@ionic/react';
import { logout } from '@/actions/user';

export default function LoadingPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showLogout, setShowLogout] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShowLogout(true), 10_000);
    return () => clearTimeout(timeout);
  });
  const logOut = async () => {
    await dispatch(logout());
    history.push('/');
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Loading....</IonTitle>
          <IonButtons slot="primary">
            {showLogout && <IonButton onClick={logOut}>Log Out</IonButton>}
          </IonButtons>
          <IonProgressBar type="indeterminate"/>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen/>
    </IonPage>
  );
}
