import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonBackButton,
  IonProgressBar,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonSpinner
} from '@ionic/react';
import classnames from 'classnames';

import { getLoad, cancelLoad } from '@/actions/loads';
import { loadNumber } from '@/helpers/humanize';
import { initCamera } from '@/helpers/qr_code';
import { preventDefault } from '@/helpers/events';
import useResourceLoader from '@/hooks/useResourceLoader';

import NotFoundPage from '@/pages/NotFoundPage';

export default function CancelPage() {
  const {uuid} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useResourceLoader(getLoad(uuid));
  const load = useSelector((state) => _.find(state.loads, {uuid}));

  const [cancelReason, setCancelReason] = useState('');
  const [cancelReasonTouched, setCancelReasonTouched] = useState(false);

  const [saving, setSaving] = useState(false);
  const cancelReasonValid = !!(cancelReason.length ?? 0 > 0);


  const submitCancel = async () => {
    try {
      setSaving(true);
      const data = await dispatch(cancelLoad({
        uuid,
        cancelReason
      }));
      history.goBack();
    } catch (e) {
      setSaving(false);
      alert(_.get(e, 'response.data.error', e.message));
    }
  }

  if (!loading && !load) return <NotFoundPage type="Load"/>;

  return load && (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/loads/${uuid}`}/>
          </IonButtons>
          <IonTitle>Cancel Load</IonTitle>
          {(loading || saving) && <IonProgressBar type="indeterminate"/>}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={preventDefault(submitCancel)}>
          <IonList>
            <IonItem lines="none">
              <h4>Load {loadNumber(load.number)}</h4>
            </IonItem>
            <IonItem lines="none">
              <IonTextarea label="Cancel Reason (this will be sent to the customer)"
                name="cancel_reason"
                value={cancelReason}
                errorText="Enter a cancelation reason"
                onIonInput={(e) => setCancelReason(e.target.value)}
                onIonBlur={(e) => setCancelReasonTouched(true)}
                className={classnames({'ion-valid': cancelReasonValid, 'ion-invalid': !cancelReasonValid, 'ion-touched': cancelReasonTouched})}
                labelPlacement="floating"
                autoGrow/>
            </IonItem>
          </IonList>
          <IonButton type="submit" expand="block" className="ion-padding" color="warning" disabled={!cancelReasonValid || saving}>
            {saving ? <IonSpinner name="dots"/> : "Confirm Cancel"}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};
