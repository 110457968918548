import { renderSVG as QRCodeSVG } from 'uqr';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

export function initCamera() {
  BarcodeScanner.prepare();
  return true;
}

const BASE_URL = import.meta.env.VITE_SPA_HOST;

export const urlToQRCode = (url) => `data:image/svg+xml,${encodeURIComponent(QRCodeSVG(url))}`;
export const loadQRCode = (load) => urlToQRCode(`${BASE_URL}/loads/${load.uuid}`);
export const companySignupQRCode = (uuid) => urlToQRCode(`${BASE_URL}/signup/?lc=${uuid}`);
