import { getLocation } from './locations';
import { getUser } from './user';

export function getLoads() {
  return async (dispatch, getState, {api, cable}) => {
    const {user: {uuid, user_type}} = getState();
    const channel = user_type === 'employee' ? 'CompanyLoadsChannel' : 'ConsumerLoadsChannel';
    await cable.subscribe(channel, {user_id: uuid}, getLoads());
    const {data} = await api.getLoads();
    if (data.loads) {
      dispatch({type: 'loads/load', loads: data.loads});
    }
    return data;
  }
}

export function getLoad(uuid) {
  return async (dispatch, _getState, {api, cable}) => {
    const {data} = await api.getLoad(uuid);
    if (data.load) {
      dispatch({type: 'load/load', load: data.load});
      if (!cable.isSubscribed('LocationChannel', {id: data.load.location_id})) {
        dispatch(getLocation(data.load.location_id));
      }
    }
    return data;
  }
}

export function createLoad({pickupLockerId, locationId, washingInstructions, consumerId, destinationLocationId}) {
  if (consumerId) {
    return async (dispatch, _getState, {api, cable}) => {
      const {data} = await api.createLoad({consumerId, washingInstructions, destinationLocationId});
      if (data.load) {
        dispatch({type: 'load/load', load: data.load});
      }
      return data;
    }
  } else {
    if (_.isEmpty(pickupLockerId) && _.isEmpty(locationId)) {
      throw new TypeError("pickupLockerId or locationId must be specified");
    }

    return async (dispatch, _getState, {api, cable}) => {
      if (locationId) await cable.subscribe('LocationChannel', {id: locationId}, getLocation(locationId));

      const {data} = await api.createLoad({pickupLockerId, locationId, washingInstructions});
      if (data.load) {
        dispatch({type: 'load/load', load: data.load});
      }
      return data;
    }
  }
}

export function collectLoad({uuid, pickup_locker_id, location_id}) {
  return async (dispatch, _getState, {api, cable}) => {
    if (location_id) {
      await cable.subscribe('LocationChannel', {id: location_id}, getLocation(location_id));
    }
    const {data} = await api.updateLoad(uuid, {state: "collected"});
    if (data.load) {
      dispatch({type: 'load/load', load: data.load});
    }
    return data;
  }
}

export function deliverLoad({uuid}, size) {
  return async (dispatch, getState, {api, cable}) => {
    const {data} = await api.updateLoad(uuid, {state: "ready_for_pickup", size});
    if (data.load) {
      cable.subscribe('LocationChannel', {id: data.load.location_id}, getLocation(data.load.location_id));
      dispatch({type: 'load/load', load: data.load});
    }
    return data;
  }
}

export function completeLoad({uuid, delivery_locker_id, delivery_location_id}) {
  return async (dispatch, _getState, {api, cable}) => {
    if (delivery_location_id) {
      await cable.subscribe('LocationChannel', {id: delivery_location_id}, getLocation(delivery_location_id));
    }

    const {data} = await api.updateLoad(uuid, {state: "completed"});

    if (data.load) {
      dispatch({type: 'load/load', load: data.load});
    }
    return data;
  }
}

export function updateLoadState({uuid}, state) {
  return async (dispatch, _getState, {api, cable}) => {
    const {data} = await api.updateLoad(uuid, {state});
    if (data.load) {
      dispatch({type: 'load/load', load: data.load});
    }
    return data;
  }
}

export function weighLoad({uuid, ...params}) {
  return async (dispatch, _getState, {api, cable}) => {
    const {data} = await api.updateLoad(uuid, {state: "weighed", ...params});
    if (data.load) {
      dispatch({type: 'load/load', load: data.load});
    }
    return data;
  }
}

export function acceptLoad({uuid}, cardToken, cardId, saveCard, postal) {
  return async (dispatch, _getState, {api, cable}) => {
    const cardParams = cardId ? {cardId} : {cardToken, saveCard, postal};
    const {data} = await api.updateLoad(uuid, {state: 'accepted', ...cardParams});

    if (data.load) {
      dispatch({type: 'load/load', load: data.load});
    }
    if (saveCard) {
      dispatch(getUser());
    }
    return data;
  }
}

export function cancelLoad({uuid, cancelReason}) {
  return async (dispatch, _getState, {api, cable}) => {
    const {data} = await api.updateLoad(uuid, {state: "canceled", stateReason: cancelReason});
    if (data.load) {
      dispatch({type: 'load/load', load: data.load});
    }
    return data;
  }
}
