import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { IonButton, IonItem, IonSpinner } from '@ionic/react';

import { login } from '@/actions/user';
import { preventDefault } from '@/helpers/events';
import { validEmail, validPassword } from '@/helpers/validators';
import ValidatedInput from '@/components/ValidatedInput';
import PasswordInput from '@/components/PasswordInput';

const LoginForm = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailValid = validEmail(email);
  const passwordValid = validPassword(password);
  const valid = emailValid && passwordValid;

  const submitLogin = async () => {
    setErrorMessage(null);
    if (!valid) return;

    setLoading(true);
    try {
      const result = await dispatch(login({email, password}));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response?.status === 401) {
        setErrorMessage("Enter a valid email address and password");
      } else {
        setErrorMessage(_.get(e, 'response.data.error', e.message));
      }
    }
  };

  return (
    <form style={{textAlign: 'left'}} onSubmit={preventDefault(submitLogin)}>
      {errorMessage && <IonItem color="danger">{errorMessage}</IonItem>}
      <ValidatedInput label="Email"
        type="email"
        name="email"
        value={email}
        setValue={setEmail}
        valid={emailValid}
        errorText="Enter a valid email"
        required
        autofocus/>
      <PasswordInput label="Password"
        name="password"
        value={password}
        setValue={setPassword}
        valid={passwordValid}
        errorText="Passwords must be at least 8 characters long"
        required/>
      <IonButton type="submit" expand="block" disabled={loading}>
        {loading ? <IonSpinner name="dots"/> : "Log In"}
      </IonButton>
    </form>
  );
};

export default LoginForm;
