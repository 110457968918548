const rootVar = (varName) => (
  window.getComputedStyle(document.getElementById('root')).getPropertyValue(varName)
);
const htmlVar = (varName) => (
  window.getComputedStyle(document.documentElement).getPropertyValue(varName)
);
const cssValue = (varName) => (
  varName.startsWith('--') ? (rootVar(varName) || htmlVar(varName)) : varName
);
const stylesToCSSString = (styles) => _.map(styles, (properties, selector) => (
  `${selector}{${_.map(properties, (value, property) => `${property}:${cssValue(value)}`).join(';')}}`
)).join('');

const PAGE_STYLES = {
  'body,select,input': {
    'box-sizing': 'border-box',
    margin: '0',
    background: '--ion-item-background',
    color: '--ion-text-color',
    'font-family': '--ion-font-family',
    'font-size': '16px',
    border: 'none',
  },
  'input,select': {
    height: '30px',
    'border-bottom': '2px solid transparent',
    'border-bottom-color': '--ion-color-medium-shade',
  },
  label: {
    display: 'inline-block',
    'margin-top': '12px'
  },
  'input:focus,select:focus': {
    outline: 'none',
    'border-bottom-color': '--ion-color-primary',
  },
  'input.error,select.error': {
    outline: 'none',
    'border-bottom-color': '--ion-color-danger',
  }
};

export const paystriUrl = (params, company) => {
  const PAYSTRI_URL = new URL(getTokenSrc(company));
  const PAYSTRI_PARAMS = new URLSearchParams(PAYSTRI_URL.search);

  _.each(params, (value, key) => PAYSTRI_PARAMS.append(key, value));
  PAYSTRI_PARAMS.append("css", stylesToCSSString(PAGE_STYLES));

  PAYSTRI_URL.search = PAYSTRI_PARAMS.toString();
  return PAYSTRI_URL;
}

const ENV_VARS = {
  VITE_TOKEN_FRAME_SRC_URL: import.meta.env.VITE_TOKEN_FRAME_SRC_URL,
  VITE_TOKEN_FRAME_LL_SRC_URL: import.meta.env.VITE_TOKEN_FRAME_LL_SRC_URL,
  VITE_TOKEN_FRAME_TEST_SRC_URL: import.meta.env.VITE_TOKEN_FRAME_TEST_SRC_URL
};

function getTokenSrc(company) {
  let srcVar;
  if (!company) srcVar = 'VITE_TOKEN_FRAME_LL_SRC_URL';
  else srcVar = company.demo ? 'VITE_TOKEN_FRAME_TEST_SRC_URL' : 'VITE_TOKEN_FRAME_SRC_URL';
  try {
    return ENV_VARS[srcVar];
  } catch {
    throw new Error(`Missing required env var ${srcVar}`);
  }
}
