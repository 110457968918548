import { MarkerF } from '@react-google-maps/api';
import { ICON_PATH, availabilityStatusColor } from '@/helpers/mapStyles';
import Point from '@/helpers/point';

export default function LocationMarker({location, selectMarker, zIndex=10}) {
  return (
    <MarkerF
      key={location.availableLockers}
      title={location.name}
      label={{
        text: `${location.availableLockers}/${location.totalLockers}`,
        fontWeight: 'bold',
        "data-cy-location": location.name
      }}
      icon={{
        path: ICON_PATH,
        anchor: {x: 5, y: 7},
        labelOrigin: {x: 5, y: 2.5},
        fillColor: availabilityStatusColor(location),
        fillOpacity: 0.9,
        scale: 5,
        strokeWeight: 0,
      }}
      position={new Point(location)}
      onClick={() => selectMarker(location)}
      zIndex={zIndex}
    />
  );
}
