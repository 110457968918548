import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
import createAPI from './api';
import createCable from './cable';

const persistConfig = {
  key: 'root',
  version: 0,
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const noopPersistor = {
  pause() {},
  persist() {},
  purge() { Promise.resolve() },
  flush() { Promise.resolve() },
  dispatch(action) { return action },
  getState() {
    return {
      registry: [],
      bootstrapped: true
    }
  },
  subscribe(callback) {
    return function noopCallbackUnsubscriber() {}
  }
}

export default function initStore(preloadedState) {
  let store;
  const getToken = () => _.get(store?.getState(), 'user.token');
  const safeDispatch = (action) => store?.dispatch(action);
  const api = createAPI(getToken, safeDispatch);
  const cable = createCable(getToken, safeDispatch);

  store = createStore(
    persistedReducer,
    preloadedState,
    compose(applyMiddleware(thunk.withExtraArgument({api, cable})))
  );
  const persistor = persistStore(store);
  return {store, persistor, cable};
}
