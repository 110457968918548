import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IonButton, IonItem, IonSpinner, IonToast } from '@ionic/react';

import { preventDefault } from '@/helpers/events';
import { validEmail } from '@/helpers/validators';
import { forgotPassword } from '@/actions/user';

import ValidatedInput from '@/components/ValidatedInput';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [notice, setNotice] = useState();
  const [email, setEmail] = useState('');

  const emailValid = validEmail(email);
  const valid = emailValid;

  const submitForgotPassword = async () => {
    setNotice(null);
    setErrorMessage(null);
    if (!valid) return;

    setLoading(true);
    try {
      await dispatch(forgotPassword(email));
      setNotice('An email has been sent to your email address. Please click the link in the email to log in');
    } catch(e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
    setLoading(false);
  }

  return (
    <>
      <form style={{textAlign: 'left'}} onSubmit={preventDefault(submitForgotPassword)}>
        {errorMessage && <IonItem lines="none" color="danger">{errorMessage}</IonItem>}
        <ValidatedInput label="Email"
          type="email"
          name="email"
          value={email}
          setValue={setEmail}
          valid={emailValid}
          errorText="Enter a valid email"
          required/>
        <IonButton type="submit" expand="block" disabled={!valid || loading}>
          {loading ? <IonSpinner name="dots"/> : "Send Login Link"}
        </IonButton>
      </form>
      <IonToast
        color="success"
        isOpen={!!notice}
        message={notice}
        onDidDismiss={() => setNotice(null)}
        duration={5000}/>
    </>
  )
}

export default ForgotPasswordForm;
