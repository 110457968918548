import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import {
  useIonViewDidEnter, IonButtons, IonButton, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar
} from '@ionic/react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import useLocationWatcher from '@/hooks/useLocationWatcher';
import { getCompany } from '@/actions/companies';
import { getCompanyLocations } from '@/actions/locations';
import { setUserLocation } from '@/actions/user';
import { isDarkMode } from '@/helpers/platform';
import { initCamera } from '@/helpers/qr_code';
import { lightStyles, darkStyles } from '@/helpers/mapStyles';
import Point from '@/helpers/point';
import { availableLocationsForSelectedCompany } from '@/selectors/locations';

import UserMarker from '@/components/UserMarker';
import LocationMarker from '@/components/LocationMarker';
import LocationCard from '@/components/LocationCard';

const US_CENTER = {lat: 40, lng: -95};

const locationsForCompany = (store, laundry_company_id) => _.filter(store.locations, {laundry_company_id});

const MapPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);
  const selectedCompanyId = user.selected_company_id;
  const selectedCompany = useSelector((store) => _.find(store.companies, {uuid: selectedCompanyId}));
  const locations = useSelector(availableLocationsForSelectedCompany);

  const [map, setMap] = useState()
  const [center, setCenter] = useState(new Point(user.currentLocation || locations[0] || US_CENTER));
  const [displayedLocationId, setDisplayedLocationId] = useState(false);
  const displayedLocation = _.find(locations, {uuid: displayedLocationId});

  const [userPosition,] = useLocationWatcher();
  useIonViewDidEnter(() => {
    if (userPosition?.distanceTo(new Point(map?.getCenter())) > 100_000) {
      if (userPosition?.isPresent()) setCenter(userPosition);
      else if (locations[0]) setCenter(locations[0]);
    }
  }, [userPosition]);
  useEffect(() => {
    if (userPosition?.isPresent()) setCenter(userPosition);
  }, [userPosition?.isPresent()]);

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getCompany(selectedCompanyId));
      dispatch(getCompanyLocations(selectedCompanyId));
    }
    if (userPosition?.isPresent()) setCenter(userPosition);
  }, [selectedCompanyId]);

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY
  });

  const selectMarker = (location) => {
    setDisplayedLocationId(location.uuid);
    const position = new Point(location);
    if (position.isPresent()) map.panTo(position);
  };

  if (!selectedCompany?.active) return <Redirect to="/companies"/>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton routerLink="/scan" fill="solid">
              Scan
            </IonButton>
          </IonButtons>
          <IonTitle>{selectedCompany?.name} Locations</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {isLoaded && (
          <GoogleMap
            onLoad={setMap}
            mapContainerStyle={{width: "100%", height: "100%"}}
            center={center}
            zoom={11}
            options={{
              styles: isDarkMode() ? darkStyles : lightStyles,
              disableDefaultUI: true,
              zoomControl: true,
              zoomControlOptions: {position: 3}
            }}
            clickableIcons={false}
          >
            {user.currentLocation && <UserMarker location={user.currentLocation}/>}
            {_.map(locations, (location) => (
              <LocationMarker key={location.uuid} location={location} selectMarker={selectMarker}/>
            ))}
          </GoogleMap>
        )}

        {displayedLocation && (
          <LocationCard location={displayedLocation} userPosition={user.currentLocation} onClose={() => setDisplayedLocationId(null)}/>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MapPage;
