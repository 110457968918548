export function findConsumers(consumerSearchParams) {
  return async (dispatch, _getState, {api}) => {
    const {data} = await api.searchConsumers(consumerSearchParams);
    dispatch({type: 'consumer/found', search: consumerSearchParams, results: data.consumers});
    return data.consumers;
  }
}

export function createConsumer(consumerParams) {
  return async (dispatch, _getState, {api}) => {
    const {data} = await api.createConsumer(consumerParams);
    dispatch({type: 'consumer/load', consumer: data.consumer});
    return data.consumer;
  }
}
export function watchConsumer(consumerId, received=_.noop) {
  return async (dispatch, _getState, {cable}) => {
    const subscription = await cable.subscribe('UserChannel', {id: consumerId});
    subscription.received = received;
    return subscription;
  }
}
