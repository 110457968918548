import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IonButton, IonList, IonSpinner, IonText, IonRow } from '@ionic/react';

import { preventDefault } from '@/helpers/events';
import { validEmail, validName } from '@/helpers/validators';
import { createConsumer } from '@/actions/consumers';
import ValidatedInput from '@/components/ValidatedInput';

export default function NewConsumerForm({
  setErrorMessage, newConsumerPhone, setNewConsumerPhone, setSelectedConsumer
}) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const emailValid = validEmail(email);
  const nameValid = validName(name);
  const valid = _.every([emailValid, nameValid]);

  const saveConsumer = async () => {
    setSaving(true);
    setErrorMessage('');
    try {
      const consumer = await dispatch(createConsumer({name, email, phoneNumber: newConsumerPhone}));
      setSelectedConsumer(consumer);
      setNewConsumerPhone(null);
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
    setSaving(false);
  };
  const cancel = () => {
    setNewConsumerPhone(null);
  };

  return (
    <form onSubmit={preventDefault(saveConsumer)}>
      <IonList>
        <h4>New Customer Details</h4>
        <ValidatedInput label="Full Name"
          name="name"
          value={name}
          setValue={setName}
          valid={nameValid}
          errorText="Please enter the first and last name"
          required
          autofocus/>
        <ValidatedInput label="Email"
          type="email"
          name="email"
          value={email}
          setValue={setEmail}
          valid={emailValid}
          errorText="Enter a valid email"
          required/>
        <ValidatedInput label="Phone Number"
          name="phoneNumber"
          value={newConsumerPhone}
          errorText="Phone number must contain at least 10 digits"
          valid
          readonly
          required/>
      </IonList>
      <IonRow className="ion-margin-top ion-justify-content-start ion-align-items-center">
        <IonButton autofocus type="submit" disabled={saving || !valid} color="primary" tabIndex="2">
          {saving ? <IonSpinner name="dots"/> : "Add Customer"}
        </IonButton>
        <IonButton color="light" onClick={cancel}>
          Cancel
        </IonButton>
      </IonRow>
    </form>
  );
}
