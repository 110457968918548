import { combineReducers } from 'redux';
import user from './user';
import companies from './companies';
import loads from './loads';
import locations from './locations';

const authenticatedReducer = (reducer) => (state, action) => {
  if (action.type === 'auth/login') {
    return reducer(undefined, action);
  }

  return reducer(state, action);
}

const readyReducer = (state=false, action) => action.type === 'ready' ? true : state;

export default combineReducers({
  ready: authenticatedReducer(readyReducer),
  user,
  companies,
  loads: authenticatedReducer(loads),
  locations,
});
