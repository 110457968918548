import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { IonButton, IonSpinner, useIonActionSheet } from '@ionic/react';

import useLocationWatcher from '@/hooks/useLocationWatcher';
import { userInRange } from '@/helpers/locations';
import { titleize } from '@/helpers/humanize';
import { updateLoadState, collectLoad, deliverLoad, completeLoad } from '@/actions/loads';
import DistanceIndicator from '@/components/DistanceIndicator';

const lockerActionSheet = ({lockerSizes, action}) => ({
  header: 'Open Locker',
  subHeader: 'Select Size',
  buttons: [..._.map(lockerSizes, (size) => ({
    text: titleize(size),
    data: {action: size}
  })), {text: 'Cancel', role: 'cancel'}],
  onDidDismiss: ({detail: {data}}) => {
    if (data?.action) action(data?.action);
  }
})

const StateButton = ({action, saving, disabled, children}) => (
  <IonButton slot="end" onClick={action} disabled={disabled || saving}>
    {saving ? <IonSpinner name="dots"/> : children}
  </IonButton>
);

const OpenLockerButton = ({action, saving, user, location}) => {
  const [userPosition, positionError] = useLocationWatcher();
  const tooFar = !user.ignore_geofence && !userInRange(location, userPosition);

  return (
    <div>
      <StateButton action={action} disabled={tooFar} saving={saving}>Open Locker</StateButton>
      <div style={{textAlign: 'right', fontSize: 'small', marginRight: '0.4em'}}>
        <DistanceIndicator location={location} userPosition={userPosition}/>
      </div>
    </div>
  );
}

export default function NextStateButton({load, location, user}) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [present] = useIonActionSheet();

  const save = (action, ...args) => async () => {
    setSaving(true);
    try {
      const data = await dispatch(action(load, ...args));
    } catch (e) {
      alert(_.get(e, 'response.data.error', e.message));
    }
    setSaving(false);
  };
  const setState = (state) => save(updateLoadState, state);

  if (user.user_type === 'employee') {
    switch (load.state) {
    case 'dirty':
      return <OpenLockerButton action={save(collectLoad)} saving={saving} user={user} location={location}/>;
    case 'collected':
      return <StateButton action={setState('received')} saving={saving}>Receive</StateButton>;
    case 'received':
      return <IonButton slot="end" routerLink={`/loads/${load.uuid}/weigh`}>Enter Weight</IonButton>;
    case 'accepted':
      return <StateButton action={setState('washed')} saving={saving}>Washed</StateButton>;
    case 'washed':
      return <StateButton action={setState('dried')} saving={saving}>Dried</StateButton>;
    case 'dried':
      return <StateButton action={setState('folded')} saving={saving}>Folded</StateButton>;
    case 'folded':
      return <StateButton action={setState('repackaged')} saving={saving}>Repackaged</StateButton>;
    case 'repackaged':
      return <StateButton action={setState('returning')} saving={saving}>Return</StateButton>;
    case 'returning':
      const lockerSizes = _.uniq(_.map(location?.lockers, 'size'));
      const action = lockerSizes.length > 1 ? (
        () => present(lockerActionSheet({lockerSizes, action: (size) => save(deliverLoad, size)()}))
      ) : save(deliverLoad);
      return <OpenLockerButton action={action} saving={saving} user={user} location={location}/>;
    }
  } else if (user.user_type === 'consumer') {
    switch (load.state) {
    case 'ready_for_pickup':
      return <OpenLockerButton action={save(completeLoad)} saving={saving} user={user} location={location}/>;
    }
  }

  return null;
}
