import { useEffect } from 'react';
import { IonApp, IonButton, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useLocation, useHistory } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from '@rollbar/react';
import { init } from '@/actions/user';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/main.scss';

import Menu from './components/Menu';
import Page from './pages/Page';
import LandingPage from './pages/LandingPage';
import LoadingPage from './pages/LoadingPage';
import SignUpPage from './pages/SignUpPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import SetPasswordPage from './pages/SetPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import EmailConfirmationPage from './pages/EmailConfirmationPage';
import AccountPage from './pages/AccountPage';
import MapPage from './pages/MapPage';
import ScanPage from './pages/ScanPage';
import CompanyPage from './pages/CompanyPage';
import CompanyResourcesPage from './pages/CompanyResourcesPage';
import CompanySelectionPage from './pages/CompanySelectionPage';
import CompanyLoadsPage from './pages/CompanyLoadsPage';
import CompanyLoadPage from './pages/CompanyLoadPage';
import CompanyLocationPage from './pages/CompanyLocationPage';
import CompanyLocationEditPage from './pages/CompanyLocationEditPage';
import CompanyLocationsPage from './pages/CompanyLocationsPage';
import CompanyLockerPage from './pages/CompanyLockerPage';
import ConsumerLoadsPage from './pages/ConsumerLoadsPage';
import ConsumerLoadPage from './pages/ConsumerLoadPage';
import PaymentPage from './pages/PaymentPage';
import NewCompanyLoadPage from './pages/NewCompanyLoadPage';
import NewConsumerLoadPage from './pages/NewConsumerLoadPage';
import ConsumerCompanyPage from './pages/ConsumerCompanyPage';
import WeighPage from './pages/WeighPage';
import CancelPage from './pages/CancelPage';

import AppUrlListener from '@/components/AppUrlListener'

import { activeCompanySelectedSelector } from '@/selectors/companies';

const history = createHistory();

const ErrorPage = ({error}) => (
  <Page title="Error">
    <p className="ion-padding" style={{textAlign: 'center'}}>
      {import.meta.env.PROD ? (
        "Something went wrong. This error has been logged for review by our dev team."
      ) : (
        <div style={{textAlign: 'left'}}>
          {error.message}<br/><br/>{error.stack}
        </div>
      )}
    </p>
  </Page>
);

const NotFoundPage = () => {
  return <Page title="Not Found" contentClassName="ion-text-center ion-justify-content-center">
    <p className="ion-padding" style={{textAlign: 'center'}}>
      Not Found. Isn't this just like a missing sock?
    </p>
    <IonButton routerLink="/">Go Home</IonButton>
  </Page>
}

const withBoundary = (Component) => (props) => (
  <ErrorBoundary fallbackUI={ErrorPage}><Component {...props}/></ErrorBoundary>
);

const UnauthenticatedApp = () => (
  <IonApp>
    <IonReactRouter history={history}>
      <AppUrlListener/>
      <IonSplitPane contentId="main">
        <IonRouterOutlet id="main">
          <Route path="/" exact component={LandingPage}/>
          <Route path="/signup" exact component={SignUpPage}/>
          <Route path="/forgot_password" exact component={ForgotPasswordPage}/>
          <Route component={LandingPage}/>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  </IonApp>
);

const ConsumerDefaultRedirect = () => {
  const activeCompanySelected = useSelector(activeCompanySelectedSelector);
  return <Redirect to={activeCompanySelected ? '/map' : '/companies'}/>;
}

const ConsumerApp = () => {
  return <IonApp>
    <IonReactRouter history={history}>
      <AppUrlListener/>
      <IonSplitPane contentId="main">
        <Menu />
        <IonRouterOutlet id="main">
          <Route path="/" exact component={ConsumerDefaultRedirect}/>
          <Route path="/loads" exact component={withBoundary(ConsumerLoadsPage)}/>
          <Route path="/loads/new" exact component={withBoundary(NewConsumerLoadPage)}/>
          <Route path="/locations/:uuid([0-9a-f\\-]+)" exact component={withBoundary(NewConsumerLoadPage)}/>
          <Route path="/loads/:uuid([0-9a-f\\-]+)" exact component={withBoundary(ConsumerLoadPage)}/>
          <Route path="/loads/:uuid([0-9a-f\\-]+)/pay" exact component={withBoundary(PaymentPage)}/>
          <Route path="/companies" exact component={withBoundary(CompanySelectionPage)}/>
          <Route path="/companies/:uuid([0-9a-f\\-]+)" exact component={withBoundary(ConsumerCompanyPage)}/>
          <Route path="/map" exact component={withBoundary(MapPage)}/>
          <Route path="/scan" exact render={() => <ErrorBoundary fallbackUI={ErrorPage}><ScanPage type="locations"/></ErrorBoundary>}/>
          <Route path="/account" exact component={AccountPage}/>
          <Route path="/reset_password" exact component={ResetPasswordPage}/>
          <Route path="/set_password" exact component={SetPasswordPage}/>
          <Route path="/email_confirmation" exact component={EmailConfirmationPage}/>
          <Route component={NotFoundPage}/>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  </IonApp>
};

const EmployeeApp = () => (
  <IonApp>
    <IonReactRouter history={history}>
      <AppUrlListener/>
      <IonSplitPane contentId="main">
        <Menu />
        <IonRouterOutlet id="main">
          <Route path="/" exact><Redirect to="/loads/active"/></Route>
          <Route path="/loads/:uuid([0-9a-f\\-]+)" exact component={withBoundary(CompanyLoadPage)}/>
          <Route path="/loads/:category([a-z_]+)" exact component={withBoundary(CompanyLoadsPage)}/>
          <Route path="/loads/:uuid([0-9a-f\\-]+)/weigh" exact component={withBoundary(WeighPage)}/>
          <Route path="/loads/:uuid([0-9a-f\\-]+)/cancel" exact component={withBoundary(CancelPage)}/>
          <Route path="/loads/new" exact component={withBoundary(NewCompanyLoadPage)}/>
          <Route path="/scan" exact render={() => <ErrorBoundary fallbackUI={ErrorPage}><ScanPage type="loads"/></ErrorBoundary>}/>
          <Route path="/locations" exact component={withBoundary(CompanyLocationsPage)}/>
          <Route path="/locations/:uuid([0-9a-f\\-]+)" exact component={withBoundary(CompanyLocationPage)}/>
          <Route path="/locations/:uuid([0-9a-f\\-]+)/edit" exact component={withBoundary(CompanyLocationEditPage)}/>
          <Route path="/locations/:location_uuid([0-9a-f\\-]+)/lockers/:uuid([0-9a-f\\-]+)" exact component={withBoundary(CompanyLockerPage)}/>
          <Route path="/account" exact component={AccountPage}/>
          <Route path="/company" exact component={CompanyPage}/>
          <Route path="/company/resources" exact component={CompanyResourcesPage}/>
          <Route path="/reset_password" exact component={ResetPasswordPage}/>
          <Route component={NotFoundPage}/>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  </IonApp>
);

const App = () => {
  const dispatch = useDispatch();
  const userType = useSelector((store) => store.user?.user_type);
  const ready = useSelector((store) => store.ready);

  useEffect(() => {
    if (userType) dispatch(init());
  }, [userType]);

  if (!userType) return <UnauthenticatedApp/>;
  if (!ready) return <LoadingPage/>;
  return userType == 'consumer' ? <ConsumerApp/> : <EmployeeApp/>;
};

export default App;
