import {
  IonButtons,
  IonBackButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonProgressBar
} from '@ionic/react';
import { ErrorBoundary } from '@rollbar/react';

const ErrorMessage = () => (
  <p className="ion-padding" style={{textAlign: 'center'}}>
    Something went wrong. This error has been logged for review by our dev team. Please try again later.
  </p>
)

const Page = ({backDefault, title, primaryButton, loading, contentClassName, children}) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {backDefault !== null && (
            <IonButtons slot="start">
              {backDefault ? <IonBackButton defaultHref={backDefault}/> : <IonMenuButton />}
            </IonButtons>
          )}
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="primary">
            {primaryButton}
          </IonButtons>
          {loading && <IonProgressBar type="indeterminate"/>}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className={contentClassName}>
        <ErrorBoundary fallbackUI={ErrorMessage}>
          {children}
        </ErrorBoundary>
      </IonContent>
    </IonPage>
  );
};

export default Page;
