import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IonButton, IonLabel, IonList, IonItem, IonSpinner, IonText } from '@ionic/react';

import { validPhoneNumber } from '@/helpers/validators';
import { findConsumers } from '@/actions/consumers';
import ValidatedInput from '@/components/ValidatedInput';

export default function ConsumerSearch({
  loading, setLoading, setErrorMessage, setSelectedConsumer, setNewConsumerPhone
}) {
  const dispatch = useDispatch();
  const [consumers, setConsumers] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');

  const searchConsumers = async (phoneNumber) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const consumers = await dispatch(findConsumers({phoneNumber}));
      setConsumers(consumers);
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
    setLoading(false);
  };

  const createConsumer = () => {
    setNewConsumerPhone(phoneNumber);
    setSelectedConsumer(null);
  };

  useEffect(() => {
    if (validPhoneNumber(phoneNumber)) {
      searchConsumers(phoneNumber);
    } else {
      setConsumers([]);
    }
  }, [phoneNumber]);

  return <>
    <ValidatedInput label="Enter customer phone number"
      labelPlacement="floating"
      fill="outline"
      errorText="Phone number must contain at least 10 digits"
      value={phoneNumber}
      valid={validPhoneNumber(phoneNumber)}
      setValue={setPhoneNumber}/>
    {!_.isEmpty(consumers) ? <>
      <h3>Select Customer</h3>
      <IonList>
        {consumers.map((consumer) => (
          <IonItem key={consumer.uuid}>
            <IonLabel>
              {consumer.name}
              <p>{consumer.phone_number}</p>
              <p>{consumer.email}</p>
            </IonLabel>
            {consumer.active ? (
              <IonButton size="default" onClick={() => setSelectedConsumer(consumer)}>
                Select
              </IonButton>
            ) : (
              <IonText color="warning" align="right">Inactive,<br/>contact support</IonText>
            )}
          </IonItem>
        ))}
      </IonList>
    </> : loading ? (
      <p>Seaching...</p>
    ) : (
      validPhoneNumber(phoneNumber) && <>
        <p>No customers found</p>
        <IonButton size="default" color="secondary" onClick={createConsumer}>
          Create Customer
        </IonButton>
      </>
    )}
  </>;
}
