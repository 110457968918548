import { createContext, createElement } from 'react';

import createCableDispatcher from './cable/cableDispatcher';
import locationsTranslator from './cable/locationsTranslator';
import lockersTranslator from './cable/lockersTranslator';
import loadsTranslator from './cable/loadsTranslator';
import usersTranslator from './cable/usersTranslator';

import { createConsumer } from '@rails/actioncable';

const CABLE_URL = (import.meta.env.VITE_API || "/api/v1/").replace('/api/v1/', '/cable').replace('http', 'ws') ?? '/cable';

const getSubscription = (subscriptions, channel, params={}) => {
  const subscription = _.find(subscriptions, (subscription) => {
    if (subscription?.identifier) {
      return _.matches({channel, ...params})(JSON.parse(subscription.identifier));
    }

    return false;
  });
  return subscription;
}

const tranlators = [lockersTranslator, loadsTranslator, locationsTranslator, usersTranslator];

function createCable(getToken, dispatch) {
  if (window.Cypress) return createMockCable(getToken, dispatch);
  let token, instance;

  const cableDispatcher = createCableDispatcher(dispatch, ...tranlators);

  function resetIfTokenHasChanged() {
    if (getToken() !== token) {
      token = getToken();
    } else {
      return;
    }

    if (!token) {
      instance?.disconnect();
      instance = undefined;
      return;
    }

    const url = new URL(CABLE_URL, window.location.href);
    url.searchParams.set('token', token);

    instance = createConsumer(url.toString());

    return instance;
  }

  resetIfTokenHasChanged();

  return {
    subscribe(channel, params={}, reconnectAction) {
      resetIfTokenHasChanged();

      let subscription = getSubscription(instance.subscriptions.subscriptions, channel, params);
      if (subscription) {
        return subscription;
      }

      return new Promise((resolve) => {
        subscription = instance.subscriptions.create({channel, ...params}, {
          received: cableDispatcher.received(subscription),
          disconnected: (...args) => console.log(`disconnected from ${channel} ${_.map(params, (k, v) => [k,v].join(':')).join(' ')}`, ...args),
          connected({reconnected}) {
            resolve(subscription);
            if (reconnected && reconnectAction) dispatch(reconnectAction);
          }
        });
      });
    },

    unsubscribe(channel, params={}) {
      const subscription = getSubscription(instance.subscriptions.subscriptions, channel, ...params);
      channel.subscriptions.remove(subscription);
    },

    isSubscribed(channel, params={}) {
      return !!getSubscription(instance?.subscriptions.subscriptions, channel, params);
    }
  }
}


export class MockSubscription {
  constructor({channel, ...params}) {
    this.channel = channel;
    this.params = params;
  }

  subscribe() {
    MockSubscription.instances.push([this, this.matcher]);
    return this;
  }

  unsubscribe() {
    MockSubscription.instances = _.reject(MockSubscription.instances, ([subscription]) => subscription === this);
    return this;
  }

  perform(...args) {
    // noop
  }

  send() {
    // noop
  }

  get matcher() {
    return {channel: this.channel, ...this.params};
  }

  get subscribed() {
    !!_.find(MockSubscription.instances, ([subscription]) => subscription === this);
  }

  static instances = [];
  static clearAll() {
    this.instances = [];
  }

  static get(indentifier) {
    const [subscription, matcher] = _.find(this.instances, ([subscription, matcher]) => {
      return _.isEqual(matcher, indentifier);
    }) || [];
    return subscription
  }

  static remove(identifier) {
    const [subscription, matcher] = _.find(this.instances, ([subscription, matcher]) => {
      return _.isEqual(matcher, indentifier);
    });
    return subscription.unsubscribe();
  }

  static #receiver = _.noop
  static set receiver(receiver) {
    this.#receiver = receiver || _.noop;
  }

  static receive(message) {
    this.#receiver(message)
  }
}

function createMockCable (getToken, dispatch) {
  if (window.Cypress) window.MockSubscription = MockSubscription;

  const cableDispatcher = createCableDispatcher(dispatch, ...tranlators);

  const createMockSubscription = ({channel, ...params}, {connected, ...actions}) => {
    _.defer(connected);
    return new MockSubscription({channel, ...params}).subscribe();
  }

  MockSubscription.receiver = cableDispatcher.received(null);

  return {
    subscribe(channel, params={}) {
      let subscription = MockSubscription.get({channel, ...params});
      if (subscription) {
        return subscription;
      }

      return new Promise((resolve) => {
        const connected = () => resolve(subscription);
        subscription = createMockSubscription({channel, ...params}, {
          disconnected: (...args) => console.log(`disconnected from MOCK ${channel} ${_.map(params, (k, v) => [k,v].join(':')).join(' ')}`, ...args),
          connected
        });
      });
    },

    unsubscribe(channel, params={}) {
      MockSubscription.remove({channel, ...params})
    },

    isSubscribed(channel, params={}) {
      return MockSubscription.get(channel, params)?.subscribed;
    }
  }
}

export const CableContext = createContext();

export const Provider = ({ value, cable=value, children}) => {
  return createElement(CableContext.Provider, { value: cable }, children);
}

export default createCable;
