import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IonCheckbox, IonButton, IonItem, IonSpinner } from '@ionic/react';

import { signUp } from '@/actions/user';
import { preventDefault } from '@/helpers/events';
import { validName, validEmail, validPassword, validPhoneNumber } from '@/helpers/validators';
import ValidatedInput from '@/components/ValidatedInput';
import PasswordInput from '@/components/PasswordInput';

const SignUpForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [agree, setAgree] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const emailValid = validEmail(email);
  const passwordValid = validPassword(password);
  const nameValid = validName(name);
  const phoneNumberValid = validPhoneNumber(phoneNumber);
  const valid = _.every([emailValid, passwordValid, nameValid, phoneNumberValid, agree]);

  const submitSignUp = async () => {
    setErrorMessage(null);
    if (!valid) return;

    setLoading(true);
    try {
      const selectedCompanyId = localStorage.getItem('laundryCompanyId');
      const result = await dispatch(signUp({email, password, name, phoneNumber, selectedCompanyId}));
      setLoading(false);
      history.push('/')
    } catch (e) {
      setLoading(false);
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
  };

  return (
    <form style={{textAlign: 'left'}} onSubmit={preventDefault(submitSignUp)}>
      {errorMessage && <IonItem color="danger">{errorMessage}</IonItem>}
      <ValidatedInput label="Name"
        name="name"
        value={name}
        setValue={setName}
        valid={nameValid}
        errorText="Please enter your first and last name"
        required
        autofocus/>
      <ValidatedInput label="Phone Number"
        name="phoneNumber"
        value={phoneNumber}
        setValue={setPhoneNumber}
        valid={phoneNumberValid}
        errorText="Phone number must contain at least 10 digits"
        required/>
      <ValidatedInput label="Email"
        type="email"
        name="email"
        value={email}
        setValue={setEmail}
        valid={emailValid}
        errorText="Enter a valid email"
        required/>
      <PasswordInput label="Password"
        name="password"
        value={password}
        setValue={setPassword}
        valid={passwordValid}
        errorText="Passwords must be at least 8 characters long"
        required/>
      <IonCheckbox
        labelPlacement="end"
        required
        className="ion-padding-vertical"
        checked={agree}
        onIonChange={(e) => setAgree(e.target.checked)}
      >
        I agree to the <a href="http://laundry-lockers.com/terms-conditions/" target="_blank" style={{pointerEvents: 'all'}}>
          terms and conditions
        </a>
      </IonCheckbox>

      <IonButton type="submit" expand="block" disabled={!valid || loading}>
        {loading ? <IonSpinner name="dots"/> : "Create Account"}
      </IonButton>
    </form>
  );
};

export default SignUpForm;
