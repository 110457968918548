import { IonRouterLink } from '@ionic/react';
import NarrowPage from '@/components/NarrowPage';
import LoginForm from '@/components/LoginForm';

const LandingPage = () => {
  return (
    <NarrowPage>
      <LoginForm/>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <IonRouterLink routerLink="/signup">Sign Up</IonRouterLink>
      </div>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <IonRouterLink routerLink="/forgot_password">Forgot Password</IonRouterLink>
      </div>
    </NarrowPage>
  );
};

export default LandingPage;
