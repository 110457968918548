import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonItemDivider,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';
import {
  personCircleOutline,
  logOutOutline,
  basketOutline,
  businessOutline,
  mapOutline,
  addCircleOutline
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { logout } from '@/actions/user';
import { titleize } from '@/helpers/humanize';
import { STATE_CATEGORIES, CATEGORY_ICONS } from '@/helpers/loads';

import './Menu.scss';

const loadIndexPages = [
  ..._.keys(STATE_CATEGORIES).map((category) => ({
    title: `${titleize(category)} Loads`,
    url: `/loads/${category}`,
    icon: CATEGORY_ICONS[category],
  }))
];

const managerPages = [
  {
    title: "Locations",
    url: `/locations`,
    icon: mapOutline,
  },
]

const consumerPages = [
  {
    title: "Find a Locker",
    url: `/map`,
    icon: mapOutline,
  },
  {
    title: "My Laundry",
    url: `/loads`,
    icon: basketOutline,
  }
];

const MenuItem = ({url, title, icon, location}) => (
  <IonItem className={location.pathname === url ? 'selected' : ''}
    routerLink={url}
    routerDirection="none"
    lines="none"
    detail={false}
  >
    <IonIcon aria-hidden="true" slot="start" icon={icon}/>
    <IonLabel>{title}</IonLabel>
  </IonItem>
);

const Menu = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const userIsEmployee = user?.user_type === 'employee';
  const userIsManager = _.includes(['owner', 'manager'], user?.role);
  const userIsOwner = user?.role === 'owner';

  const appPages = userIsEmployee ? loadIndexPages : consumerPages;
  const logOut = async () => {
    await dispatch(logout());
    history.push('/');
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="main-nav">
          <IonListHeader>Laundry Lockers</IonListHeader>
          {user?.name && <IonNote>{user.name}</IonNote>}

          {userIsEmployee && <>
            <IonMenuToggle autoHide={false}>
              <MenuItem location={location} url="/loads/new" title="Start a Load" icon={addCircleOutline}/>
            </IonMenuToggle>
            <IonItemDivider/>
          </>}

          <IonMenuToggle autoHide={false}>
            {appPages.map((appPage, index) => (
              <MenuItem key={index} location={location} {...appPage}/>
            ))}
          </IonMenuToggle>

          {userIsManager && <>
            <IonItemDivider/>
            <IonMenuToggle autoHide={false}>
              {userIsOwner && <MenuItem location={location} url="/company" title="Company" icon={businessOutline}/>}
              {managerPages.map((appPage, index) => (
                <MenuItem key={index} location={location} {...appPage}/>
              ))}
            </IonMenuToggle>
          </>}

          <IonItemDivider/>
          <IonMenuToggle autoHide={false}>
            <MenuItem location={location} url="/account" title="My Account" icon={personCircleOutline}/>
            <IonItem lines="none" detail={false} button onClick={logOut}>
              <IonIcon aria-hidden="true" slot="start" icon={logOutOutline}/>
              <IonLabel>Log Out</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
