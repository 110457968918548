import { createSelector } from 'reselect';

export const activeCompaniesSelector = createSelector(
  (state) => state.companies,
  (companies) => (
    _.filter(companies, 'active')
  )
);

export const activeCompanySelectedSelector = createSelector(
  (state) => state.user?.selected_company_id,
  (state) => state.companies,
  (selected_company_id, companies) => {
    const company = _.find(companies, {uuid: selected_company_id});
    return company?.active;
  }
);
