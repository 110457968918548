export default function loadsReducer(state=null, action) {
  switch(action.type) {
    case 'loads/load':
      return action.loads;
    case 'load/load':
      return _.uniqBy([action.load, ...(state || [])], 'uuid');
    default:
      return state;
  }
}
