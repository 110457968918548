import { basketOutline, carOutline, fileTrayOutline, sparklesOutline, checkmarkDoneOutline } from 'ionicons/icons';

export const DIRTY = 'dirty';
export const PICKUP_STATES = [DIRTY, 'collected'];
export const INTAKE_STATES = ['received'];
export const IN_PROGRESS_STATES = ['accepted', 'washed', 'dried', 'folded'];
export const DELIVERY_STATES = ['repackaged', 'rejected', 'returning', 'ready_for_pickup'];
export const ACTIVE_STATES = [...PICKUP_STATES, ...INTAKE_STATES, 'weighed', ...IN_PROGRESS_STATES, ...DELIVERY_STATES, 'lost'];
export const DONE_STATES = ['completed', 'canceled'];
export const STATES = [...ACTIVE_STATES, ...DONE_STATES];
export const STATE_CATEGORIES = {
  active: ACTIVE_STATES,
  pickup: PICKUP_STATES,
  intake: INTAKE_STATES,
  in_progress: IN_PROGRESS_STATES,
  delivery: DELIVERY_STATES,
  done: DONE_STATES
};
export const CATEGORY_ICONS = {
  active: basketOutline,
  pickup: carOutline,
  intake: fileTrayOutline,
  in_progress: sparklesOutline,
  delivery: carOutline,
  done: checkmarkDoneOutline
};

export const readyForPayment = (load) => {
  return _.every([
    load.payment_status !== 'paid',
    load.payment_status !== 'authorized',
    load.state === 'weighed'
  ]);
};

const SHOW_DONE_FOR_DAYS = 3;

export const current = (load) => {
  const old = new Date() - (SHOW_DONE_FOR_DAYS * 24 * 60 * 60 * 1000);
  return _.includes(ACTIVE_STATES, load.state) || new Date(load.updated_at) > old;
}
