import { useSelector } from 'react-redux';
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon
} from '@ionic/react';
import { downloadOutline } from 'ionicons/icons';

import { getCompany } from '@/actions/companies';
import { companySignupQRCode } from '@/helpers/qr_code';
import useResourceLoader from '@/hooks/useResourceLoader';
import Page from '@/pages/Page';

export default function CompanyResourcesPage() {
  const user = useSelector((store) => store.user);
  const loading = useResourceLoader(getCompany(user.laundry_company_id));
  const company = useSelector((store) => _.find(store.companies, {uuid: user.laundry_company_id}));

  const signupCode = companySignupQRCode(user.laundry_company_id);

  return (
    <Page title="Promotional Resources" backDefault="/company" loading={loading}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>{company?.name} Sign Up</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          Give this QR Code to customers to sign up for Laundry Lockers with your company pre-selected.
          <div className="ion-margin-vertical">
            <img src={signupCode} width="350"/>
          </div>
          <a href={signupCode} download="laundry-lockers-signup-code.svg">
            <IonButton color="tertiary"><IonIcon icon={downloadOutline}/>Download</IonButton>
          </a>
        </IonCardContent>
      </IonCard>
    </Page>
  );
}
