import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { getLoad, updateLoadState, acceptLoad } from '@/actions/loads';
import { inDollars, formatDate, titleize, presentAsLine } from '@/helpers/humanize';
import useResourceLoader from '@/hooks/useResourceLoader';
import NotFoundPage from '@/pages/NotFoundPage';
import LoadingPage from '@/pages/LoadingPage';
import PaystriFrame from '@/components/PaystriFrame';
import ValidatedInput from '@/components/ValidatedInput';

const ProcessingPaymentModal = ({isOpen, processing}) => {
  return (
    <IonModal isOpen={isOpen} canDismiss={!isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Processing Payment</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <p>Please wait while we process your payment</p>
      </IonContent>
    </IonModal>
  );
}

const getLoadCompanySelector = (load) => (state) => {
  const location = _.find(state.locations, {uuid: load?.location_id});
  return _.find(state.companies, {uuid: location?.laundry_company_id});
};

const PaymentPage = () => {
  const {uuid} = useParams();
  const dispatch = useDispatch();
  const load = useSelector((state) => _.find(state.loads, {uuid}));
  const company = useSelector(getLoadCompanySelector(load));
  const loading = useResourceLoader(getLoad(uuid));

  const cards = useSelector(({user}) => user.cards);
  const [selectedCard, setSelectedCard] = useState(_.first(cards)?.id || null);
  const [saveCard, setSaveCard] = useState(true);
  const [postal, setPostal] = useState("");

  const [token, setToken] = useState();
  const [creditCardError, setCreditCardError] = useState();
  const [processing, setProcessing] = useState(false);

  const setTokenFromMessageEvent = (response) => {
    if (response.validationError) {
      setCreditCardError(response.validationError);
    } else if (response.message) {
      setCreditCardError("")
      setToken(response.message);
    }
  };

  const acceptPriceAndPay = async () => {
    if (!selectedCard && !postal) {
      setCreditCardError("Billing ZIP Code is required");
      return;
    }
    if (!processing) {
      setProcessing(true);
      try {
        const {load:updatedLoad} = await dispatch(acceptLoad(load, token, selectedCard, saveCard, postal));
        if (updatedLoad?.payment_message) setCreditCardError(updatedLoad.payment_message);
      } catch (e) {
        setCreditCardError(_.get(e, 'response.data.error', e.message));
      }
      setProcessing(false);
    }
  };

  const rejectPrice = async () => {
    if (confirm('If you cancel at this time, you will need to contact the laundromat to retrive your laundry. Cancel anyway?')) {
      await dispatch(updateLoadState(load, 'rejected'));
    }
  };

  if (loading || !company) return <LoadingPage/>;

  if (!load) return <NotFoundPage type="Load"/>;

  if (load.state !== 'weighed') {
    return <Redirect to={`/loads/${uuid}`}/>;
  }

  return <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/loads/${uuid}`}/>
          </IonButtons>
          <IonTitle>{formatDate(load.created_at)}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {company.demo && (
          <IonItem color="warning">
            This company is in demo mode. Real credit cards will not be processed.
          </IonItem>
        )}
        <IonItem key={load.uuid}>
          <IonLabel>
            <h2>{formatDate(load.created_at)}</h2>
            <p>{load.location_name}</p>
            <p>
              {presentAsLine(
                titleize(load.state),
                load.weight_us_lbs ? `${_.round(load.weight_us_lbs, 2)} lbs` : "unweighed",
                load.weight_us_lbs && (load.price_cents ? inDollars(load.price_cents) : "free"),
              )}
            </p>
            <p>Last updated {formatDate(load.updated_at)}</p>
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonSelect label="Payment Method"
            value={selectedCard}
            onIonChange={(e) => {setSelectedCard(e.target.value); setCreditCardError('')}}
          >
            <IonSelectOption value={null}>New Card</IonSelectOption>
            {_.map(cards, (card) => (
              <IonSelectOption key={card.id} value={card.id}>
                {card.processor} *{card.last_four}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        {selectedCard === null && <>
          <IonItem lines="none">
            <PaystriFrame onMessage={setTokenFromMessageEvent} company={company}/>
          </IonItem>
          <IonItem lines="none">
            <ValidatedInput label="Billing ZIP Code"
              value={postal}
              setValue={setPostal}
              valid={postal.length === 5 || postal.length === 10}
              errorText="Enter a valid 5 digit or 9 digit postal code"
              required/>
          </IonItem>
          <IonItem lines="none">
            <IonCheckbox
              labelPlacement="end"
              justify="start"
              checked={saveCard}
              onIonChange={(e) => setSaveCard(e.target.checked)}
            >
              Save card for next time
            </IonCheckbox>
          </IonItem>
        </>}
        {creditCardError && <IonItem lines="none"><IonText color="danger">{creditCardError}</IonText></IonItem>}

        <IonButton type="submit" expand="block"
          disabled={!load || !(token || selectedCard)}
          onClick={acceptPriceAndPay}
        >
          Pay {inDollars(load.price_cents)} Now
        </IonButton>

        <IonButton expand="block"  color="medium" onClick={rejectPrice}>
          Cancel
        </IonButton>
      </IonContent>
    </IonPage>
    <ProcessingPaymentModal isOpen={processing} processing={processing}/>
  </>
}

export default PaymentPage;
