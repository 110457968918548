import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';

export default function NotFoundPage({type}) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{type} Not Found</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <p className="ion-padding" style={{textAlign: 'center'}}>
          If the problem persists, try logging out and logging back in again.
        </p>
      </IonContent>
    </IonPage>
  );
}