import { IonRouterLink } from '@ionic/react';
import ForgotPasswordForm from "@/components/ForgotPasswordForm";
import NarrowPage from '@/components/NarrowPage';

const ForgotPasswordPage = () => {
  return (
    <NarrowPage>
      <ForgotPasswordForm/>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <IonRouterLink routerLink="/" routerDirection="back">Log In</IonRouterLink>
      </div>
    </NarrowPage>
  );
}

export default ForgotPasswordPage;
