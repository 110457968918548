import { useState } from 'react';
import { IonItem } from '@ionic/react';

import Page from '@/pages/Page';
import ConsumerSearch from '@/components/ConsumerSearch';
import NewConsumerForm from '@/components/NewConsumerForm';
import NewCompanyLoadForm from '@/components/NewCompanyLoadForm';

export default function NewCompanyLoadPage() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedConsumer, setSelectedConsumer] = useState(null);
  const [newConsumerPhone, setNewConsumerPhone] = useState(null);

  return (
    <Page title="Start a Load" loading={loading}>
      {errorMessage && <IonItem color="danger">{errorMessage}</IonItem>}
      <section className="ion-padding">
        {!selectedConsumer ? (
          !newConsumerPhone ? (
            <ConsumerSearch {...{loading, setLoading, setErrorMessage, setSelectedConsumer, setNewConsumerPhone}}/>
          ) : (
            <NewConsumerForm {...{setErrorMessage, newConsumerPhone, setNewConsumerPhone, setSelectedConsumer}}/>
          )
        ) : (
          <NewCompanyLoadForm {...{selectedConsumer, setSelectedConsumer, setErrorMessage}}/>
        )}
      </section>
    </Page>
  );
}
