import { IonRouterLink } from '@ionic/react';
import ResetPasswordForm from "@/components/ResetPasswordForm";
import Page from '@/pages/Page';

const ForgotPasswordPage = () => {
  return (
    <Page title="Reset Password" contentClassName="ion-padding" backDefault="/account">
      <ResetPasswordForm/>
    </Page>
  );
}

export default ForgotPasswordPage;
