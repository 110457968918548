import { IonItem, IonLabel } from '@ionic/react';
import { inDollars, formatDate, presentAsLine, loadNumber } from '@/helpers/humanize';

export default function LoadItem({load}) {
  return (
    <IonItem routerLink={`/loads/${load.uuid}`} routerDirection="forward">
      <IonLabel>
        <h2>{loadNumber(load.number)} for {load.consumer_name || load.consumer_email}</h2>
        <p>{load.pickup_location_name}</p>
        <p>
          {presentAsLine(
            load.status_note,
            load.weight_us_lbs ? `${_.round(load.weight_us_lbs, 2)} lbs` : "unweighed",
            load.weight_us_lbs && (load.price_cents ? inDollars(load.price_cents) : "free"),
            `Last updated ${formatDate(load.updated_at)}`
          )}
        </p>
      </IonLabel>
    </IonItem>
  );
}
