export const preventDefault = (func=_.noop) => (e) => {
  e.preventDefault();
  return func(e);
}

export const stopPropagation = (func=_.noop) => (e) => {
  e.stopPropagation();
  return func(e);
}

export const stopEverything = (func=_.noop) => (e) => {
  e.preventDefault();
  e.stopPropagation();
  return func(e);
}
