import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  IonButton,
  IonLabel,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonRow
} from '@ionic/react';

import { createLoad } from '@/actions/loads';
import { watchConsumer } from '@/actions/consumers';
import { preventDefault } from '@/helpers/events';
import ValidatedTextarea from '@/components/ValidatedTextarea';

export default function NewCompanyLoadForm({
  selectedConsumer, setSelectedConsumer, setErrorMessage
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const locations = useSelector((state) => state.locations);

  const [selectedLocationId, setSelectedLocationId] = useState(_.first(locations)?.uuid);
  const [washingInstructions, setWashingInstructions] = useState();
  const [saving, setSaving] = useState(false);

  const consumerValid = selectedConsumer?.active && selectedConsumer.email_confirmed;
  const valid = _.every([selectedLocationId, consumerValid])

  useEffect(() => {
    if (selectedConsumer?.uuid) {
      const received = ({update, user}) => {
        if (update === 'user updated' && user.uuid === selectedConsumer.uuid) {
          setSelectedConsumer(user);
        }
      };
      const subscription = dispatch(watchConsumer(selectedConsumer.uuid, received));
      return subscription.unsubscribe;
    }
  }, [selectedConsumer?.uuid]);

  const submitLoad = async () => {
    if (!valid) return;

    setSaving(true);
    setErrorMessage('');
    try {
      const {load, ...data} = await dispatch(createLoad({
        washingInstructions,
        consumerId: selectedConsumer.uuid,
        destinationLocationId: selectedLocationId,
      }));
      if (load) {
        setWashingInstructions("");
        history.replace(`/loads/${load.uuid}`);
      } else {
        console.warn(data);
        setErrorMessage(data.error);
      }
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
      console.warn(e);
    }
    setSaving(false);
  };

  return <>
    <h4>Selected Customer</h4>
    <IonItem>
      <IonLabel>
        {selectedConsumer.name}
        <p>
          {selectedConsumer.phone_number}<br/>
          {selectedConsumer.email}
        </p>
      </IonLabel>
      <IonButton size="default" color="light" onClick={() => setSelectedConsumer(null)}>
        Change
      </IonButton>
    </IonItem>
    {!consumerValid && (
      <IonItem color="warning" lines="none">
        {!selectedConsumer.active && (
          <p>This customer has been deactivated. Please contact support.</p>
        )}
        {!selectedConsumer.email_confirmed && (
          <p>This new customer has not activated their account by setting a password yet.</p>
        )}
      </IonItem>
    )}

    <h4>Load Details</h4>
    {locations?.length > 1 && (
      <IonSelect label="Select delivery locker location"
        labelPlacement="floating"
        fill="solid"
        value={selectedLocationId}
        onIonChange={(e) => setSelectedLocationId(e.target.value)}
      >
        {_.map(locations, (location) => (
          <IonSelectOption key={location.uuid} value={location.uuid}>
            {location.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    )}
  
    <form onSubmit={preventDefault(submitLoad)}>
      <ValidatedTextarea
        label="Washing instructions"
        name="washing_instructions"
        value={washingInstructions}
        setValue={setWashingInstructions}
        fill="solid"
        valid={true}/>
      <IonRow className="ion-margin-top ion-justify-content-start ion-align-items-center">
        <IonButton type="submit" disabled={saving || !valid} color="primary">
          {saving ? <IonSpinner name="dots"/> : "Start Load"}
        </IonButton>
      </IonRow>
    </form>
  </>;
}
