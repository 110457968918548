import { getLoads } from '@/actions/loads';
import { getCompany } from '@/actions/companies';
import { getCompanyLocations } from '@/actions/locations';

export function login({email, password}) {
  return async (dispatch, _getState, {api}) => {
    const {data} = await api.login({email, password});
    if (data.token) {
      dispatch({type: 'auth/login', token: data.token});
      return await dispatch(init());
    }
    return data;
  }
}

export function loginWithToken(token) {
  return async (dispatch, _getState, {api}) => {
    const {data} = await api.getUserWithToken(token);
    if (data.token && data.user) {
      dispatch({type: 'auth/login', token: data.token, user: data.user});
      dispatch(init());
    }
    return data.user;
  }
}

export function refreshToken() {
  return async (dispatch, getState, {api}) => {
    const {user} = getState();
    if (!user) return false;

    const {data} = await api.getUserWithToken(user.token);
    if (data.token && data.user) {
      dispatch({type: 'auth/refresh', token: data.token, user: data.user});
    }
    return data.user;
  }
}

export function signUp({email, password, name, phoneNumber, selectedCompanyId}) {
  return async (dispatch, _getState, {api}) => {
    const {data} = await api.signUp({
      email, password, name, phoneNumber, selectedCompanyId, agreedToTermsAt: new Date()
    });
    if (data.user) {
      await dispatch(login({email, password}));
    }
    return data;
  }
}

export function logout() {
  return {type: 'auth/logout'};
}

export function getUser() {
  return async (dispatch, _getState, {api, cable}) => {
    const {data} = await api.getUser();
    await cable.subscribe('UserChannel', {id: data.user.uuid}, getUser());
    dispatch({type: 'user/load', user: data.user});
    return data.user;
  }
}

export function setSelectedCompany(uuid) {
  return async (dispatch, _getState, {api}) => {
    dispatch({type: 'user/setSelected', selected_company_id: uuid});
    const {data} = await api.updateUser({selected_company_id: uuid});
    const {selected_company_id} = data.user

    dispatch({type: 'user/setSelected', selected_company_id});
    await dispatch(getCompanyLocations(selected_company_id));

    return selected_company_id;
  }
}

export function setUserLocation(location) {
  return {type: 'user/setLocation', location};
}

export function forgotPassword(email) {
  return async (_dispatch, _getState, {api}) => {
    const {data} = await api.forgotPassword(email)
    return data.success;
  }
}

export function resetPassword(password, agree) {
  return async (_dispatch, _getState, {api}) => {
    const {data} = await api.resetPassword(password, agree);
    return data.success;
  }
}

export function confirmEmail(confirmationCode) {
  return async (dispatch, _getState, {api}) => {
    const {data} = await api.confirmEmail(confirmationCode);
    dispatch({type: 'user/load', user: data.user});
    return data.success;
  }
}

export function requestConfirmationEmail() {
  return async (_dispatch, _getState, {api}) => {
    const {data} = await api.requestConfirmationEmail();
    return data.success;
  }
}

export function init() {
  return async (dispatch, getState, {api}) => {
    await dispatch(getUser());
    dispatch(getLoads());

    const {user} = getState();
    const companyId = user?.laundry_company_id || user?.selected_company_id;
    if (companyId) {
      await dispatch(getCompany(companyId));
      dispatch(getCompanyLocations(companyId));
    }
    dispatch({type: 'ready'});
  }
}
