import { useState } from 'react';
import { IonInput } from '@ionic/react';
import classnames from 'classnames';

export default function ValidatedInput({setValue, valid, ...props}) {
  const [touched, setTouched] = useState(false);
  return (
    <IonInput
      onIonInput={(e) => setValue(e.target.value)}
      onIonBlur={(e) => setTouched(true)}
      className={classnames({'ion-valid': valid, 'ion-invalid': !valid, 'ion-touched': touched})}
      labelPlacement="floating"
      {...props}/>
  );
}
