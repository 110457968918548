import { reduceItemWith } from './helpers';

const reduceLocker = reduceItemWith('locker_id');
const mergeLocker = (locker, action) => ({...locker, ...action.locker});

function lockersReducer(state=null, action) {
  switch(action.type) {
    case 'locker/update':
      return reduceLocker(state, action, mergeLocker);
    default:
      return state;
  }
}

function locationReducer(state, action) {
  return {
    ...state,
    lockers: lockersReducer(state.lockers, action)
  };
}

const reduceLocation = reduceItemWith('location_id');

export default function locationsReducer(state=null, action) {
  switch (action.type) {
    case 'companyLocations/load':
      return action.locations;
    case 'companyLocation/load':
      return _.uniqBy([action.location, ...(state ?? [])], 'uuid');
    default:
      return reduceLocation(state, action, locationReducer);
  }
}
