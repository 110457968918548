import pluralize from 'pluralize';
import classnames from 'classnames';
import {
  IonButton, IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonIcon, IonItem
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { userInRange } from '@/helpers/locations';
import { inDollars, daysOfTheWeek } from '@/helpers/humanize';

import '@/theme/location-card.scss';

export default function LocationCard({location, userPosition, onClose}) {
  const onMap = !!onClose;
  const openDirections = () => window.open(`https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`, '_blank');
  return (
    <IonCard className={classnames("location-card", {onMap})}>
      {location.promotion && (
        <IonItem color="tertiary">{location.promotion}</IonItem>
      )}
      <IonCardHeader>
        <IonCardTitle>{location.name}</IonCardTitle>
        {onMap && <>
          <IonCardSubtitle>
            {pluralize('lockers', location.availableLockers, true)} available right now
          </IonCardSubtitle>
          <IonIcon icon={closeOutline} className="close-button" onClick={onClose}/>
        </>}
      </IonCardHeader>
      <IonCardContent>
        {location.address}
        {location.hours && (
          <p className="hours">{location.hours}</p>
        )}
        {!_.isEmpty(location.pickup_days) && (
          <p><em>Picked up/dropped off {daysOfTheWeek(location.pickup_days)}</em></p>
        )}
      </IonCardContent>
      {onMap && (
        userInRange(location, userPosition) ? (
          <IonButton  fill="clear" routerLink={`/loads/new?location_id=${location.uuid}`}>
            Start a Load
          </IonButton>
        ) : (
          <IonButton fill="clear" onClick={openDirections}>
            Directions
          </IonButton>
        )
      )}
      <IonBadge color="success" className="price-badge">
        {inDollars(location.cost_cents)}/lb
      </IonBadge>
    </IonCard>
  );
}
