export function getCompanies({location}={}) {
  return async (dispatch, getState, {api}) => {
    const {data} = await api.getCompanies({location});
    dispatch({type: 'companies/load', companies: data.companies});
    return data.companies;
  }
}

export function getCompany(uuid) {
  if (!uuid) throw new TypeError("argument 1, 'uuid', must have a value");
  return async (dispatch, getState, {api}) => {
    const {data} = await api.getCompany(uuid);
    dispatch({type: 'company/load', company: data.company});
    return data.company;
  }
}

export function updateCompany(company) {
  return async (dispatch, getState, {api}) => {
    const {data} = await api.updateCompany(company);
    dispatch({type: 'company/load', company: data.company});
    return data.company;
  }
}
