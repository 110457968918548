import { useState, useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import pluralize from 'pluralize';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonProgressBar,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonRow
} from '@ionic/react';

import useResourceLoader from '@/hooks/useResourceLoader';
import useLocationWatcher, { LOCATION_PERMISSION_DENIED } from '@/hooks/useLocationWatcher';
import { setSelectedCompany } from '@/actions/user';
import { getLocation } from '@/actions/locations';
import { createLoad } from '@/actions/loads';
import { userInRange } from '@/helpers/locations';
import { preventDefault } from '@/helpers/events';
import { inDollars, daysOfTheWeek } from '@/helpers/humanize';

import LockerOpenModal from '@/components/LockerOpenModal';
import DistanceIndicator from '@/components/DistanceIndicator';
import ValidatedTextarea from '@/components/ValidatedTextarea';

const MAX_DIRTY_LOADS = 3;

export default function NewConsumerLoadPage() {
  const {uuid} = useParams();
  const pathLocation = useLocation();
  const searchParams = new URLSearchParams(pathLocation.search);
  const locationId = uuid || searchParams.get('location_id');

  const dispatch = useDispatch();
  const loading = useResourceLoader(locationId && getLocation(locationId), [locationId]);

  const user = useSelector((state) => state.user);
  const dirtyLoadCount = useSelector((state) => _.filter(state.loads, {state: 'dirty', location_id: locationId}).length);
  const location = useSelector((state) => _.find(state.locations, {uuid: locationId}));
  const demoCompany = useSelector((state) => _.find(state.companies, {uuid: location?.laundry_company_id})?.demo);
  const availableLockers = _.filter(location?.lockers, {occupied: false, state: 'locked'});

  const [errorMessage, setErrorMessage] = useState();
  const [saving, setSaving] = useState(false);
  const [load, setLoad] = useState();

  const [washingInstructions, setWashingInstructions] = useState();
  const [washingInstructionsValid, setWashingInstructionsValid] = useState(true);
  const [washingInstructionsTouched, setWashingInstructionsTouched] = useState(false);

  const [userPosition, positionError] = useLocationWatcher();
  const tooFar = !userInRange(location, userPosition);
  const tooManyLoads = dirtyLoadCount >= MAX_DIRTY_LOADS;

  const submitLoad = async () => {
    setSaving(true);
    setErrorMessage('');
    try {
      const {load, ...data} = await dispatch(createLoad({locationId, washingInstructions}));
      if (load) {
        setLoad(load);
        setWashingInstructions("");
      } else {
        console.warn(data);
        setErrorMessage(data.error);
      }
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
      console.warn(e);
    }
    setSaving(false);
  };

  useEffect(() => {
    if (!user?.selected_company_id && location?.laundry_company_id) {
      dispatch(setSelectedCompany(location?.laundry_company_id));
    }
  }, [location?.laundry_company_id]);

  return <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/map"/>
          </IonButtons>
          <IonTitle>{location?.name ?? (loading ? 'Loading...' : 'Location not found')}</IonTitle>
          {loading && <IonProgressBar type="indeterminate"/>}
        </IonToolbar>
      </IonHeader>

      {!loading && location && (
        load ? (
          <IonContent fullscreen>
            <section className="ion-padding">
              <h3>You're all set!</h3>
              <p>You'll get a text message when your laundry has been weighed with payment instructions.</p>
            </section>
            <IonList>
              <IonItem routerLink={`/loads/${load.uuid}`}>Track your laundry</IonItem>
              <IonItem button onClick={() => setLoad(null)}>Start another load</IonItem>
              <IonItem routerLink={`/companies/${load.laundry_company_id}`}>Contact laundry company</IonItem>
            </IonList>
          </IonContent>
        ) : (
          <IonContent fullscreen>
            <section className="ion-padding">
              <h3>Start a Load</h3>
              {availableLockers.length > 0 ? (
                <form onSubmit={preventDefault(submitLoad)}>
                  {errorMessage && <IonItem color="danger">{errorMessage}</IonItem>}
                  <ValidatedTextarea
                    label="Washing instructions"
                    name="washing_instructions"
                    value={washingInstructions}
                    setValue={setWashingInstructions}
                    fill="outline"
                    helperText="optional"
                    valid={true}/>
                  <IonRow className="ion-margin-top ion-justify-content-start ion-align-items-center">
                    <IonButton autofocus type="submit" disabled={saving || tooFar || tooManyLoads} color="primary" tabIndex="2">
                      {saving ? <IonSpinner name="dots"/> : "Open Locker"}
                    </IonButton>
                    <div className="ion-padding-horizontal">
                      <div>
                        {pluralize('lockers', availableLockers.length, true)} available
                      </div>
                      {!!userPosition && (tooFar || demoCompany) && (
                        <div><DistanceIndicator location={location} userPosition={userPosition}/></div>
                      )}
                    </div>
                  </IonRow>

                  <IonList>
                    {tooManyLoads && (
                      <IonItem color="warning" lines="none" className="ion-padding-vertical">
                        You already have {dirtyLoadCount} loads started. If this is in error, please see an attendant.
                      </IonItem>
                    )}
                    {!!userPosition && tooFar && (
                      <IonItem color="warning" lines="none" className="ion-padding-vertical">
                        You'll need to be closer to the lockers to open them.
                      </IonItem>
                    )}
                    {!userPosition && positionError && (
                      <IonItem color="warning" lines="none" className="ion-padding-vertical">
                        {positionError.code === LOCATION_PERMISSION_DENIED ? (
                          "You must enable location for this app to open a locker."
                        ) : `Geolocation Error: ${positionError.message}`}
                      </IonItem>
                    )}
                  </IonList>
                </form>
              ) : (
                <p>Sorry, no lockers are currently available at this location.</p>
              )}
            </section>
            <IonItemDivider>
              <IonLabel>Location Details</IonLabel>
            </IonItemDivider>
            <section className="ion-padding">
              <p><IonText color="success"><strong>{inDollars(location.cost_cents)}/lb</strong></IonText></p>
              {location.hours && (
                <p style={{whiteSpace: 'pre-wrap'}}>{location.hours}</p>
              )}
              {!_.isEmpty(location.pickup_days) && (
                <p><em>Picked up/dropped off {daysOfTheWeek(location.pickup_days)}</em></p>
              )}
            </section>
          </IonContent>
        )
      )}
    </IonPage>
    <LockerOpenModal user={user} load={load} location={location}/>
  </>;
}
