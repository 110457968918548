import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Geolocation } from '@capacitor/geolocation';
import Point from '@/helpers/point';
import { setUserLocation } from '@/actions/user';

export const LOCATION_PERMISSION_DENIED = 1;
export const LOCATION_POSITION_UNAVAILABLE = 2;
export const LOCATION_TIMEOUT = 3;

export default function useLocationWatcher() {
  const [error, setError] = useState(null);
  const [location, setLocation] = useState(null);
  const dispatch = useDispatch();

  const setPosition = (position) => {
    const userPosition = new Point(position.coords);
    setError(null);
    setLocation(userPosition);
    dispatch(setUserLocation(userPosition));
  };

  useEffect(() => {
    const watch = Geolocation.watchPosition({
      enableHighAccuracy: true, maximumAge: 15000, timeout: 3000
    }, (position, err) => {
      if (position?.coords) {
        setPosition(position);
      } else {
        setError(err);
        console.warn("no location", position, err);
        if (err.code === LOCATION_TIMEOUT) {
          Geolocation.getCurrentPosition({maximumAge: Infinity}).then(setPosition).catch(setError);
        }
      }
    });
    return () => Geolocation.clearWatch(watch);
  }, []);

  return [location, error];
}
