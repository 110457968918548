import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IonRouterLink, IonButton, IonItem, IonSpinner, IonToast } from '@ionic/react';

import { preventDefault } from '@/helpers/events';
import { logout, confirmEmail, requestConfirmationEmail } from '@/actions/user';
import Page from '@/pages/Page';
import ValidatedInput from '@/components/ValidatedInput';

const EmailConfirmationPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {email} = useSelector((state) => state.user);

  const [saving, setSaving] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const confirmationCodeValid = /^\d{6}$/.test(confirmationCode);

  const logOut = async (e) => {
    e.preventDefault()
    await dispatch(logout());
    history.push(e.target.href);
  };

  const submitEmailConfirmation = async () => {
    setSaving(true);
    setErrorMessage("");
    try {
      const result = await dispatch(confirmEmail(confirmationCode));
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
    setSaving(false);
  };

  const requestNewEmail = async () => {
    setErrorMessage("");
    try {
      const result = await dispatch(requestConfirmationEmail());
      setEmailSent(true);
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
  }

  useEffect(() => {
    if (confirmationCodeValid) submitEmailConfirmation();
  }, [confirmationCodeValid]);

  return (
    <Page title="Confirm Your Email" backDefault={null}>
      {errorMessage && <IonItem color="danger">{errorMessage}</IonItem>}
      <div className="ion-text-center ion-padding">
        <p><em>{email}</em></p>

        <form onSubmit={preventDefault(submitEmailConfirmation)}>
          <ValidatedInput label="Enter the six digit code that was emailed to you"
            value={confirmationCode}
            setValue={setConfirmationCode}
            valid={confirmationCodeValid}
            errorText="Confirmation code must be 6 numbers"
            fill="outline"
            required/>
          <IonButton type="submit" expand="block" disabled={!confirmationCodeValid || saving}>
            {saving ? <IonSpinner name="dots"/> : "Verify Email"}
          </IonButton>
        </form>

        <h3 className="ion-padding-top">Didn't get the email?</h3>

        <IonButton type="submit"
          color="tertiary"
          fill="outline"
          onClick={requestNewEmail}
          disabled={emailSent}
        >
          Resend Confirmation Email
        </IonButton>
        <p>Please make sure your email address is correct</p>

        <h3>Not your email?</h3>
        <IonRouterLink routerLink="/signup" routerDirection="root" onClick={logOut}>
          Sign Up Again
        </IonRouterLink>
        <p>or</p>
        <IonRouterLink routerLink="/" routerDirection="root" onClick={logOut}>
          Log In As a Different User
        </IonRouterLink>
      </div>
      <IonToast
        color="success"
        isOpen={emailSent}
        message="A new confirmation email is on the way to your inbox!"
        onDidDismiss={() => setEmailSent(false)}
        duration={15000}/>
    </Page>
  );
}

export default EmailConfirmationPage;
