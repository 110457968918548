import ResetPasswordForm from "@/components/ResetPasswordForm";
import Page from '@/pages/Page';

const SetPasswordPage = () => {
  return (
    <Page title="Set Password" contentClassName="ion-padding" backDefault={null}>
      <ResetPasswordForm initial/>
    </Page>
  );
}

export default SetPasswordPage;
