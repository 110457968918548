import jwtDecode from 'jwt-decode';

export default function userReducer(state=null, action) {
  switch (action.type) {
    case 'auth/login':
    case 'auth/refresh':
      const {exp: token_exp} = jwtDecode(action.token);
      const token_iss = new Date();
      return {
        token: action.token, token_exp, token_iss,
        ...(action.user || {})
      };
    case 'auth/logout':
      return null;
    case 'user/load':
      return {...(state || {}), ...action.user};
    case 'user/update':
      if (state.uuid !== action.user.uuid) return state;
      return {...(state || {}), ...action.user};
    case 'user/setSelected':
      return {...state, selected_company_id: action.selected_company_id};
    case 'user/setLocation':
      return {...state, currentLocation: action.location};
    default:
      return state;
  }
}
