import { inDollars } from '@/helpers/humanize';

export default function PriceDescription({load}) {
  if (!_.first(load.line_items?.description)) return;
  return (
    <p>
      {load.weight_us_lbs > 0 && <>
        {inDollars(load.cost_cents * load.weight_us_lbs)} by weight
        {_.first(load.line_items).amount_cents > 0 ? '+' : '-'}
      </>}
      {inDollars(_.first(load.line_items).amount_cents)} for {_.first(load.line_items).description}
    </p>
  );
}
