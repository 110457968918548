import { useState, useEffect } from 'react';
import { IonButton, IonHeader, IonIcon, IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

import { isDarkMode } from '@/helpers/platform';
import LOGO from '@/assets/logo-lg.png';
import LOGO_DARK from '@/assets/logo-lg.dark.png';


export default function AndroidAppBanner() {
  const [androidInstallPrompt, setAndroidInstallPrompt] = useState(window.androidInstallPrompt);
  const [onAndroidBrowser, setOnAndroidBrowser] = useState(false);
  const [hidden, setHidden] = useState(localStorage.getItem('appBannerHidden'));

  useEffect(() => {
    if (Capacitor.isNativePlatform()) return;
    Device.getInfo().then(
      ({operatingSystem}) => setOnAndroidBrowser(operatingSystem === 'android')
    );
  })

  useEffect(() => {
    const promptListener = (e) => setAndroidInstallPrompt(e);
    window.addEventListener('beforeinstallprompt', promptListener);
    return () => window.removeEventListener('beforeinstallprompt', promptListener);
  })
  if (hidden || !onAndroidBrowser || !androidInstallPrompt) return;

  const hide = () => {
    setHidden(true);
    localStorage.setItem('appBannerHidden', true);
  };

  const open = () => {
    if (!setAndroidInstallPrompt) {
      return window.location = "https://play.google.com/store/apps/details?id=com.laundrylockers.mobile";
    }

    androidInstallPrompt.prompt();
    androidInstallPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          hide();
        }
        setAndroidInstallPrompt(null);
      });
  };

  return (
    <IonHeader>
      <IonItem color="light">
        <IonIcon icon={closeOutline} className="close-button" onClick={hide}/>
        <IonThumbnail className="app-banner-icon">
          <img src={isDarkMode() ? LOGO_DARK : LOGO} alt="Laundry Lockers"/>
        </IonThumbnail>
        <IonLabel>
          Laundry Lockers
          <p>FREE - In Google Play</p>
        </IonLabel>
        <IonButton onClick={open}>View</IonButton>
      </IonItem>
    </IonHeader>
  );
}
